import React from 'react';
import {
  Edit, SimpleForm, TextInput, NumberInput, FormDataConsumer,
  ReferenceInput, SelectInput, required, type EditProps,
} from 'react-admin';
import FifaInput from '../../components/inputs/FifaInput';
import RivalTeamInput from '../../components/inputs/RivalTeamInput';
import CustomEditTitle from '../../components/utils/CustomEditTitle';

const TeamEdit: React.FC<EditProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  return (
    <Edit
      redirect="show"
      title={<CustomEditTitle resource="team" />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <NumberInput
          source="budget"
          label="Budget (€)"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <NumberInput
          source="fifaIndexId"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <FifaInput
          source="fifaId"
          label="FIFA"
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) => formData.fifaId !== undefined &&
            <>
              <RivalTeamInput
                source="rivalTeamId"
                label="Rival team"
                view="edit"
                fifa={formData.fifaId}
              />
              <ReferenceInput source="squadId" reference="squad" filter={{ fifaId: formData.fifaId }}>
                <SelectInput
                  source="name"
                  variant="filled"
                  label="Squad"
                  sx={{
                    width: 219,
                  }}
                />
              </ReferenceInput>
            </>
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default TeamEdit;
