import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, TextField as MuiTextField,
  useMediaQuery, type Theme,
} from '@mui/material';
import {
  Datagrid, FunctionField, NumberField, ListContextProvider, Pagination,
  ReferenceField, TextField, useList, useNotify, type Identifier,
  type RaRecord,
} from 'react-admin';
import type { SelectPlayerDialogProps, Player } from '../../interfaces';

const SelectPlayerDialog: React.FC<SelectPlayerDialogProps> = ({
  open,
  setOpen,
  setSelectedPlayer,
  playersInSquad,
  setPlayersInSquad,
  order,
  playerList,
}) => {
  const [players, setPlayers] = React.useState<Player[]>();
  const [playersCopy, setPlayersCopy] = React.useState<Player[]>([]);
  const [filterValue, setFilterValue] = React.useState<string>('');

  const notify = useNotify();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const listContext = useList({
    data: players,
    perPage: 10,
  });

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterValue(event.target.value);
    if (event.target.value.length === 0) setPlayers(playersCopy);
    else {
      const filtered: Player[] = playersCopy.filter(
        (player: Player) => player.lastName.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()),
      );
      setPlayers(filtered);
    }
  };

  const handleRowClick = (id: Identifier, resource: string, record: RaRecord): false => {
    const alreadyInSquad = playersInSquad.filter((player: RaRecord) => player.id === id);
    if (alreadyInSquad.length === 0) {
      setSelectedPlayer(record);
      const newPlayers = [
        ...playersInSquad,
        record,
      ];
      const withOrder = newPlayers.map((player: RaRecord) => {
        return player.id === id ? {
          ...player,
          order,
          sub: 0,
        } : {
          ...player,
          sub: 0,
        };
      });
      setPlayersInSquad(withOrder);
    } else notify('Player is already in squad', { type: 'error' });
    setOpen(false);
    return false;
  };

  React.useEffect(() => {
    const filtered = playerList.filter((player: Player) =>
      !playersInSquad.some((playerInSquad) => player.id === playerInSquad.id),
    );
    setPlayers(filtered);
    setPlayersCopy(filtered);
  }, [playersInSquad]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Select player</DialogTitle>
      <DialogContent>
        <ListContextProvider value={listContext}>
          <MuiTextField
            label="Last name"
            variant="filled"
            size="small"
            value={filterValue}
            onChange={handleFilter}
          />
          <Datagrid
            bulkActionButtons={false}
            rowClick={(id, resource, record) => handleRowClick(id, resource, record)}
            resource="player"
          >
            <FunctionField label="Name" render={(record: Player) => `${record.firstName} ${record.lastName}`} />
            <NumberField source="rating" />
            {!isSmall ? <ReferenceField source="positionId" reference="position" link={false}>
              <TextField source="name" />
            </ReferenceField> : null}
          </Datagrid>
          <Pagination rowsPerPageOptions={[5, 10, 25]} />
        </ListContextProvider>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPlayerDialog;
