import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Dialog, DialogTitle, DialogContent, IconButton, Button, Box,
  Typography, Stack,
} from '@mui/material';
import get from 'lodash/get';
import {
  Form, ReferenceInput, SelectInput, AutocompleteInput, ReferenceArrayInput,
  AutocompleteArrayInput, NumberInput, required, useDataProvider,
  useShowContext, useNotify,
} from 'react-admin';
import type { AddSeasonDialogProps, SeasonFormData, ManagedSeasonToSave } from '../../interfaces';

const AddSeasonDialog: React.FC<AddSeasonDialogProps> = ({ open, setOpen, setRefreshSeasons }) => {
  const [fifa, setFifa] = React.useState<number>(0);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { record } = useShowContext();

  const createManagedSeason = async (dataToSave: ManagedSeasonToSave): Promise<void> => {
    await dataProvider.create('managed_season', {
      data: dataToSave,
    });
  };

  const onSubmit = (formData: Partial<SeasonFormData>): void => {
    const dataToSave: ManagedSeasonToSave = {
      careerId: formData.id ?? 0,
      seasonId: formData.seasonId ?? 0,
      teamId: formData.teamId ?? 0,
      trophyIds: formData.trophyIds ?? null,
      finalPosition: formData.position ?? 0,
      points: formData.points ?? 0,
      squadId: formData.squadId ?? null,
    };
    createManagedSeason(dataToSave).then(() => {
      notify('Season successfully added', { type: 'success' });
      if (setRefreshSeasons !== undefined) setRefreshSeasons(true);
      setOpen(false);
    }).catch(() => {
      notify('Duplicate entry', { type: 'error' });
    });
  };

  React.useEffect(() => {
    if (record !== undefined) setFifa(get(record, ['fifaId'], 0));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            Add season
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={onSubmit}>
          <Stack direction="column" spacing={0}>
            <ReferenceInput
              source="seasonId"
              reference="season"
              filter={{
                fifaId: fifa,
              }}
            >
              <SelectInput
                variant="filled"
                optionText="name"
                optionValue="id"
                validate={required()}
                sx={{
                  width: 219,
                }}
              />
            </ReferenceInput>
            <ReferenceInput
              source="teamId"
              reference="team"
              filter={{
                fifaId: fifa,
              }}
            >
              <AutocompleteInput
                source="name"
                variant="filled"
                validate={required()}
                optionText="name"
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 2;
                }}
                noOptionsText="Type 3 characters"
                sx={{
                  width: 219,
                }}
              />
            </ReferenceInput>
            <ReferenceArrayInput
              source="trophyIds"
              reference="trophy"
              filter={{
                fifaId: fifa,
              }}
            >
              <AutocompleteArrayInput
                source="name"
                label="Trophies"
                variant="filled"
                optionText="name"
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 1;
                }}
                noOptionsText="Type 2 characters"
                sx={{
                  width: 219,
                }}
              />
            </ReferenceArrayInput>
            <NumberInput
              source="position"
              label="League position"
              variant="filled"
              validate={required()}
              min={1}
              max={30}
              sx={{
                width: 219,
              }}
            />
            <NumberInput
              source="points"
              variant="filled"
              validate={required()}
              min={0}
              max={150}
              sx={{
                width: 219,
              }}
            />
            <ReferenceInput
              source="squadId"
              reference="squad"
              filter={{
                fifaId: fifa,
              }}
            >
              <AutocompleteInput
                source="name"
                variant="filled"
                optionText="name"
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 2;
                }}
                noOptionsText="Type 3 characters"
                sx={{
                  width: 219,
                }}
              />
            </ReferenceInput>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              startIcon={<SaveIcon />}
              sx={{
                color: '#fff',
              }}
            >
              Save
            </Button>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddSeasonDialog;
