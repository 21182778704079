import React from 'react';
import { CircularProgress } from '@mui/material';

const SmallLoader: React.FC = () => {
  return (
    <CircularProgress color="primary" />
  );
};

export default SmallLoader;
