import React from 'react';
import { AppBar } from 'react-admin';

const CustomAppBar: React.FC = () => {
  return (
    <AppBar color="primary" sx={{ color: '#fff' }} />
  );
};

export default CustomAppBar;
