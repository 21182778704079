import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Stack, Button, Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@mui/material';
import type { AxiosError } from 'axios';
import {
  Form, ReferenceInput, AutocompleteInput, NumberInput, required,
  useDataProvider, useNotify, type RaRecord,
} from 'react-admin';
import type { EditNotablePlayerFormProps } from '../../interfaces';

const EditNotablePlayerForm: React.FC<EditNotablePlayerFormProps> = ({ open, setOpen, record, setRefresh }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const updatePlayer = async (player: RaRecord): Promise<RaRecord> => {
    const data = await dataProvider.update('notable_player', { id: player.id, data: player, previousData: record });
    return data.data;
  };

  const onSubmit = (data: Partial<RaRecord>): void => {
    updatePlayer(data as RaRecord)
      .then((resp: RaRecord) => {
        setRefresh(true);
        setOpen(false);
        notify('Notable player updated', { type: 'success' });
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 500) notify('Server error', { type: 'error' });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">
            Edit player
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            marginTop: '2rem',
          }}
        >
          <Form defaultValues={record} onSubmit={onSubmit}>
            <Box
              sx={{
                marginTop: '2rem',
              }}
            >
              <Stack
                direction="column"
                spacing={1}
              >
                <ReferenceInput
                  source="playerId"
                  reference="player"
                  filter={{
                    fifaId: record.fifaId,
                  }}
                >
                  <AutocompleteInput
                    source="lastName"
                    variant="filled"
                    validate={required()}
                    optionText={(record) => `${record.firstName} ${record.lastName}`}
                    optionValue="id"
                    filterToQuery={(searchText: string) => ({ lastName: searchText })}
                    shouldRenderSuggestions={(val: string) => {
                      return val.trim().length > 2;
                    }}
                    noOptionsText="Type 3 characters"
                    sx={{
                      width: 219,
                    }}
                  />
                </ReferenceInput>
                <NumberInput
                  source="appearances"
                  label="Appearances"
                  variant="filled"
                  validate={required()}
                  sx={{
                    width: 219,
                  }}
                />
                <NumberInput
                  source="goals"
                  label="Goals"
                  variant="filled"
                  validate={required()}
                  sx={{
                    width: 219,
                  }}
                />
                <NumberInput
                  source="assists"
                  label="Assists"
                  variant="filled"
                  validate={required()}
                  sx={{
                    width: 219,
                  }}
                />
                <NumberInput
                  source="yellowCards"
                  label="Yellow cards"
                  variant="filled"
                  validate={required()}
                  sx={{
                    width: 219,
                  }}
                />
                <NumberInput
                  source="redCards"
                  label="Red cards"
                  variant="filled"
                  validate={required()}
                  sx={{
                    width: 219,
                  }}
                />
                <NumberInput
                  source="cleanSheets"
                  label="Clean sheets"
                  variant="filled"
                  validate={required()}
                  sx={{
                    width: 219,
                  }}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                margin: '2rem 0px 0px',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                sx={{
                  color: '#fff',
                }}
              >
                Save
              </Button>
            </Box>
          </Form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditNotablePlayerForm;
