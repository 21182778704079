import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, TextField as MuiTextField,
  useMediaQuery, type Theme,
} from '@mui/material';
import {
  ListContextProvider, TextField, ReferenceField, FunctionField, Datagrid,
  Pagination, NumberField, useList, type Identifier, type RaRecord,
} from 'react-admin';
import type { SquadPlayersFieldDialogProps, Player, PlayerInSquad } from '../../../interfaces';
import SmallLoader from '../../utils/SmallLoader';

const SquadPlayersFieldDialog: React.FC<SquadPlayersFieldDialogProps> = ({
  open,
  setOpen,
  order,
  playersInSquad,
  setPlayersInSquad,
  setRefresh,
  playerList,
  dialogType,
}) => {
  const [players, setPlayers] = React.useState<Player[]>([]);
  const [playersComplete, setPlayersComplete] = React.useState<Player[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [filterValue, setFilterValue] = React.useState<string>('');

  const listContext = useList({
    data: players,
    perPage: 10,
  });
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterValue(event.target.value);
    if (event.target.value.length === 0) setPlayers(playersComplete);
    else {
      const filtered = playersComplete.filter(
        (player: Player) => player.lastName.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()),
      );
      setPlayers(filtered);
    }
  };

  const handleRowClick = (id: Identifier, resource: string, record: RaRecord): false => {
    if (dialogType === 'subs') {
      /* switch starting player with sub player */
      const originalPlayer = playersInSquad.filter((player: PlayerInSquad) => player.orderInSquad === order);
      const newStartingPlayers = playersInSquad.map((player: PlayerInSquad) => {
        return player.orderInSquad === order ? {
          ...player,
          playerId: id,
          positionId: record.positionId,
        } : player;
      });
      const newPlayers = newStartingPlayers.map((player: PlayerInSquad) => {
        return player.playerId === id && player.orderInSquad === 11 ? {
          ...player,
          playerId: originalPlayer[0].playerId,
          positionId: originalPlayer[0].positionId,
        } : player;
      });
      setPlayersInSquad(newPlayers);
    } else {
      /* switch starting player with unused player */
      const newPlayers = playersInSquad.map((player: PlayerInSquad) => {
        return player.orderInSquad === order ? {
          ...player,
          playerId: id,
          positionId: record.positionId,
        } : player;
      });
      setPlayersInSquad(newPlayers);
    }
    setRefresh(true);
    setOpen(false);
    return false;
  };

  React.useEffect(() => {
    if (typeof open === 'boolean' && open) {
      if (dialogType === 'subs') {
        const onlySubs = playerList.filter((player: Player) =>
          playersInSquad.some((entity: PlayerInSquad) => player.id === entity.playerId && entity.sub === 1),
        );
        setPlayers(onlySubs);
        setPlayersComplete(onlySubs);
      } else {
        const filtered = playerList.filter((player: Player) =>
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          !playersInSquad.some((entity: PlayerInSquad) => entity.playerId === player.id),
        );
        setPlayers(filtered);
        setPlayersComplete(filtered);
      }
      setLoading(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Select player</DialogTitle>
      <DialogContent>
        {!loading
          ? <ListContextProvider value={listContext}>
            <MuiTextField
              label="Last name"
              variant="filled"
              size="small"
              value={filterValue}
              onChange={handleFilter}
            />
            <Datagrid
              bulkActionButtons={false}
              rowClick={(id, resource, record) => handleRowClick(id, resource, record)}
            >
              <FunctionField label="Name" render={(record: Player) => `${record.firstName} ${record.lastName}`} />
              <NumberField source="rating" />
              {!isSmall ? <ReferenceField source="positionId" reference="position" link={false}>
                <TextField source="name" />
              </ReferenceField> : null}
            </Datagrid>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
          </ListContextProvider>
          : <SmallLoader />
        }
      </DialogContent>
    </Dialog>
  );
};

export default SquadPlayersFieldDialog;
