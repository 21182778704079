import type { AxiosError } from 'axios';
import get from 'lodash/get';
import type {
  GetListParams, GetOneParams, GetManyParams, GetManyReferenceParams,
  UpdateParams, UpdateManyParams, CreateParams, DeleteParams, DeleteManyParams,
  DataProvider, GetListResult, GetOneResult, GetManyResult, GetManyReferenceResult,
  UpdateResult, UpdateManyResult, CreateResult, DeleteResult, DeleteManyResult,
} from 'react-admin';
import { List, GetOne, GetMany, GetManyReference, Update, Create, Delete, DeleteMany } from '../services/DataService';

const dataProvider: DataProvider = {
  getList: async (resource: string, params: GetListParams): Promise<GetListResult> => {
    const { page, perPage } = params.pagination ?? { page: 1, perPage: 10 };
    const { field, order } = params.sort ?? { field: 'id', order: 'ASC' };

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage]),
      filter: JSON.stringify(params.filter),
    };
    const listData = await List(resource, query);
    const total = get(listData, ['headers', 'content-range'], listData.data.length);
    return {
      data: listData.data,
      total: parseInt(total),
    };
  },
  getOne: async (resource: string, params: GetOneParams): Promise<GetOneResult> => {
    const { id } = params;
    const getOneData = await GetOne(resource, id);

    return {
      data: getOneData[0],
    };
  },
  getMany: async (resource: string, params: GetManyParams): Promise<GetManyResult> => {
    const { ids } = params;
    const getManyData = await GetMany(resource, ids);

    return {
      data: getManyData,
    };
  },
  getManyReference: async (
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult> => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const getManyReferenceData = await GetManyReference(resource, query);
    const total = get(getManyReferenceData, ['headers', 'content-range'], getManyReferenceData.length);
    return {
      data: getManyReferenceData,
      total: parseInt(total),
    };
  },
  update: async (resource: string, params: UpdateParams): Promise<UpdateResult> => {
    const { data } = params;
    const updateData = await Update(resource, data)
      .catch((err: AxiosError) => {
        if (err.response?.status === 409) throw new Error('Duplicate entity');
      });

    return {
      data: updateData,
    };
  },
  updateMany: async (resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
    /* NOT IMPLEMENTED */
    return {
    };
  },
  create: async (resource: string, params: CreateParams): Promise<CreateResult> => {
    const { data } = params;
    const createData = await Create(resource, data)
      .catch((err: AxiosError) => {
        if (err.response?.status === 409) throw new Error('Duplicate entity');
      });

    return {
      data: createData,
    };
  },
  delete: async (resource: string, params: DeleteParams): Promise<DeleteResult> => {
    const { id, previousData } = params;
    await Delete(resource, id)
      .catch((err: AxiosError) => {
        if (err.response?.status === 422) throw new Error('Constraint violation');
      });

    return {
      data: previousData,
    };
  },
  deleteMany: async (resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
    const { ids } = params;
    await DeleteMany(resource, ids)
      .catch((err: AxiosError) => {
        if (err.response?.status === 422) throw new Error('Constraint violation');
      });

    return {
      data: ids,
    };
  },
};

export default dataProvider;
