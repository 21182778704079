import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { TextField, IconButton, Box, Tooltip, FormControl } from '@mui/material';
import { useController } from 'react-hook-form';
import type { FifaIndexInputProps } from '../../interfaces';

const FifaIndexInput: React.FC<FifaIndexInputProps> = ({ source, label }) => {
  const input = useController({ name: source }).field;
  const [value, setValue] = React.useState<string>('');

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    input.onChange(parseInt(event.target.value));
  };

  const handleScouted = (): void => {
    setValue('99999999');
    input.onChange(parseInt('99999999'));
  };

  return (
    <FormControl
      sx={{
        width: 350,
        height: '71.91px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <TextField
          type="number"
          label={`${label} *`}
          value={value}
          variant="filled"
          onChange={handleInput}
          InputProps={{ inputProps: { min: 1 } }}
          sx={{
            width: 219,
            marginTop: '4px',
          }}
        />
        <Tooltip title="Scouted">
          <IconButton
            color="primary"
            onClick={handleScouted}
            size="small"
            sx={{
              marginLeft: '5px',
            }}
          >
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </FormControl>
  );
};

export default FifaIndexInput;
