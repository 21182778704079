import React from 'react';
import { Edit, SimpleForm, TextInput, required, type EditProps } from 'react-admin';
import CustomEditTitle from '../../components/utils/CustomEditTitle';

const ManagerEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      redirect="show"
      title={<CustomEditTitle resource="manager" />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="firstName" variant="filled" validate={required()} />
        <TextInput source="lastName" variant="filled" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default ManagerEdit;
