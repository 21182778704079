import PlaceIcon from '@mui/icons-material/Place';
import PositionCreate from './Create';
import PositionEdit from './Edit';
import PositionList from './List';
import PositionShow from './Show';

const Position = {
  name: 'position',
  icon: PlaceIcon,
  list: PositionList,
  show: PositionShow,
  edit: PositionEdit,
  create: PositionCreate,
};

export default Position;
