import React from 'react';
import { useMediaQuery, type Theme } from '@mui/material';
import {
  List, Datagrid, SimpleList, NumberField, TextField,
  ReferenceField, SearchInput, type ListProps,
} from 'react-admin';
import CustomListActions from '../../components/utils/CustomListActions';

const positionListFilters = [
  <SearchInput
    source="name"
    key="name"
    variant="filled"
    alwaysOn
  />,
];

const positionListFiltersSmall = [
  <SearchInput
    source="name"
    key="name"
    variant="filled"
    alwaysOn={false}
    sx={{
      margin: '5px',
    }}
  />,
];

const PositionList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomListActions hasFilters resource="position" />}
      filters={isSmall ? positionListFiltersSmall : positionListFilters}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall
        ? <Datagrid rowClick="show">
          <NumberField source="id" />
          <TextField source="name" />
          <ReferenceField source="positionGroupId" reference="position_group" link="show">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
        : <SimpleList
          primaryText={data => data.name}
          linkType="show"
        />
      }
    </List>
  );
};

export default PositionList;
