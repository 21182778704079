import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, TextField } from '@mui/material';
import type { AxiosError } from 'axios';
import { useDataProvider, useNotify } from 'react-admin';
import type { SquadNameDialogProps, Squad } from '../../interfaces';

const SquadNameDialog: React.FC<SquadNameDialogProps> = ({ open, setOpen, setLevel, setName }) => {
  const [value, setValue] = React.useState<string>('');
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const checkIfNameAlreadyExists = async (): Promise<Squad[]> => {
    const alreadyExists = await dataProvider.getList('squad', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: { name: value },
    });
    return alreadyExists.data;
  };

  const handleSave = (): void => {
    checkIfNameAlreadyExists().then((resp: Squad[]) => {
      if (resp.length === 0) {
        setName(value);
        setLevel(2);
        setOpen(false);
      } else {
        notify('Squad with this name already exists', { type: 'error' });
      }
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Name you squad</DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          label="Squad name"
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          disabled={value.length === 0}
          sx={{
            color: '#fff',
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SquadNameDialog;
