import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TopToolbar, FilterButton, Button } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import CustomCreateButton from './CustomCreateButton';
import { type CustomListActionsProps } from '../../interfaces';

const CustomListActions: React.FC<CustomListActionsProps> = ({ hasFilters, createSquad, resource }) => {
  const navigate = useNavigate();
  return (
    <TopToolbar>
      {hasFilters !== undefined ? <FilterButton disableSaveQuery /> : null}
      {createSquad === undefined
        ? <CustomCreateButton
          resource={resource}
          hasMenu={resource === 'player' || resource === 'team'}
        />
        : <Button
          color="primary"
          size="small"
          onClick={() => navigate('/squad-builder')}
          label="Create"
        >
          <AddIcon />
        </Button>
      }
    </TopToolbar>
  );
};

export default CustomListActions;
