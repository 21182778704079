import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Dialog, DialogTitle, DialogContent, Box, Button, Typography,
  IconButton, Stack,
} from '@mui/material';
import type { AxiosError } from 'axios';
import {
  Form, NumberInput, required, useDataProvider, useNotify,
  useLogout, type RaRecord,
} from 'react-admin';
import type { CopyPlayerDialogProps } from '../../interfaces';

const CopyPlayerDialog: React.FC<CopyPlayerDialogProps> = ({ open, setOpen, record }) => {
  const notify = useNotify();
  const logout = useLogout();
  const dataProvider = useDataProvider();

  const createPlayer = async (dataToCreate: Partial<RaRecord>): Promise<RaRecord> => {
    const data = await dataProvider.create('player', { data: dataToCreate });
    return data.data;
  };

  const onSubmit = (data: Partial<RaRecord>): void => {
    const dataToCreate = {
      ...data,
    };
    delete dataToCreate.id;
    createPlayer(dataToCreate).then((resp: RaRecord) => {
      notify('Player created', { type: 'success' });
      setOpen(false);
    }).catch(async (err: AxiosError) => {
      if (err.response?.status === 401) await logout();
      if (err.response?.status === 409) notify('Duplicate entry', { type: 'error' });
      if (err.response?.status === 500) notify('Server error', { type: 'error' });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            Copy player
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={onSubmit} defaultValues={record}>
          <Stack direction="column" spacing={0}>
            <NumberInput
              source="rating"
              variant="filled"
              validate={required()}
              InputProps={{ inputProps: { min: 0, max: 99 } }}
              sx={{
                width: 219,
              }}
            />
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              startIcon={<SaveIcon />}
              sx={{
                color: '#fff',
              }}
            >
              Save
            </Button>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CopyPlayerDialog;
