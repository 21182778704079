import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, type ShowProps } from 'react-admin';
import CustomShowTitle from '../../components/utils/CustomShowTitle';

const UserShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<CustomShowTitle resource="user" />} sx={{ marginBottom: '1rem' }}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <TextField source="login" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
