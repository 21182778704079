import GroupsIcon from '@mui/icons-material/Groups';
import TeamCreate from './Create';
import TeamEdit from './Edit';
import TeamList from './List';
import TeamShow from './Show';

const Team = {
  name: 'team',
  icon: GroupsIcon,
  list: TeamList,
  show: TeamShow,
  edit: TeamEdit,
  create: TeamCreate,
};

export default Team;
