import React from 'react';
import BuildIcon from '@mui/icons-material/Build';
import {
  Grid, Card, CardContent, CardActions, Typography, Button,
  useMediaQuery, type Theme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import { Title, useNotify, useLogout, type RaRecord } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import LatestPlayersList from '../components/utils/LatestPlayersList';
import SmallLoader from '../components/utils/SmallLoader';
import TopRatedPlayersList from '../components/utils/TopRatedPlayersList';
import * as API from '../services/DataService';

const Dashboard: React.FC = () => {
  const [latestPlayers, setLatestPlayers] = React.useState<RaRecord[]>([]);
  const [topRatedPlayers, setTopRatedPlayers] = React.useState<RaRecord[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingTopRated, setLoadingTopRated] = React.useState<boolean>(true);

  const notify = useNotify();
  const logout = useLogout();
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getLatestPlayers = async (): Promise<RaRecord[]> => {
    const data = await API.GetLatestPlayers();
    return data;
  };

  const getTopRatedPlayers = async (): Promise<RaRecord[]> => {
    const data = await API.GetTopRatedPlayers();
    return data;
  };

  React.useEffect(() => {
    getLatestPlayers().then((resp: RaRecord[]) => {
      setLatestPlayers(resp);
      setLoading(false);
    }).catch(async (err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
      if (err.response?.status === 401) await logout();
    });

    getTopRatedPlayers().then((resp: RaRecord[]) => {
      setTopRatedPlayers(resp);
      setLoadingTopRated(false);
    }).catch(async (err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
      if (err.response?.status === 401) await logout();
    });
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card
            raised
            sx={{
              margin: '1rem auto 0px',
              width: '95%',
              height: '150px',
              position: 'relative',
            }}
          >
            <Title title="Fifa Squad Builder" />
            <CardContent>
              <Typography variant="h5">
                Squad Builder v 1.1.0
              </Typography>
            </CardContent>
            <CardActions sx={{ bottom: 5, position: 'absolute', display: 'flex' }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => navigate('/squad-builder')}
                startIcon={<BuildIcon />}
                sx={{
                  color: '#fff',
                }}
              >
                Squad Builder
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card sx={{ margin: '1rem auto 0px', width: '95%', height: '300px' }} raised>
            <CardContent>
              <Typography variant="h5">
                Newest players
              </Typography>
              {!loading
                ? <LatestPlayersList players={latestPlayers} />
                : <SmallLoader />
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card
            sx={{
              margin: `1rem auto ${isSmall ? '1rem' : '0px'}`,
              width: '95%',
              height: '300px',
            }}
            raised
          >
            <CardContent>
              <Typography variant="h5">
                Top rated players
              </Typography>
              {!loadingTopRated
                ? <TopRatedPlayersList players={topRatedPlayers} />
                : <SmallLoader />
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
