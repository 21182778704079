import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, type ShowProps } from 'react-admin';
import CustomShowTitle from '../../components/utils/CustomShowTitle';

const FormationShow: React.FC<ShowProps> = (props) => {
  return (
    <Show title={<CustomShowTitle resource="formation" />} sx={{ marginBottom: '1rem' }} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <NumberField source="defenders" />
        <NumberField source="midfielders" />
        <NumberField source="attackers" />
        <TextField source="layout" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FormationShow;
