import EngineeringIcon from '@mui/icons-material/Engineering';
import ManagerCreate from './Create';
import ManagerEdit from './Edit';
import ManagerList from './List';
import ManagerShow from './Show';

const Manager = {
  name: 'manager',
  icon: EngineeringIcon,
  list: ManagerList,
  show: ManagerShow,
  edit: ManagerEdit,
  create: ManagerCreate,
};

export default Manager;
