import React from 'react';
import type { AxiosError } from 'axios';
import {
  Edit, SimpleForm, TextInput, AutocompleteInput, FormDataConsumer,
  required, useDataProvider, useNotify, type EditProps, type RaRecord,
} from 'react-admin';
import FifaInput from '../../components/inputs/FifaInput';

const CareerEdit: React.FC<EditProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  const [managers, setManagers] = React.useState<RaRecord[]>([]);
  const [managersCopy, setManagersCopy] = React.useState<RaRecord[]>([]);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const getManagers = async (): Promise<RaRecord[]> => {
    const data = await dataProvider.getList('manager', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });
    return data.data;
  };

  React.useEffect(() => {
    getManagers().then((resp: RaRecord[]) => {
      setManagers(resp);
      setManagersCopy(resp);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  }, []);

  React.useEffect(() => {
    if (parseInt(selectedVal) > 0) {
      const filtered = managersCopy.filter((manager: RaRecord) => manager.fifaId === parseInt(selectedVal));
      setManagers(filtered);
    }
  }, [selectedVal]);

  return (
    <Edit
      redirect="show"
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <FifaInput
          source="fifaId"
          label="FIFA"
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
          disabled
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) => formData.fifaId !== undefined &&
            <AutocompleteInput
              source="managerId"
              choices={managers}
              variant="filled"
              validate={required()}
              optionText={(record: RaRecord) => `${record.firstName} ${record.lastName}`}
              optionValue="id"
              filterToQuery={(searchText: string) => ({ name: searchText })}
              shouldRenderSuggestions={(val: string) => {
                return val.trim().length > 2;
              }}
              noOptionsText="Type 3 characters"
              sx={{
                width: 219,
              }}
            />
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default CareerEdit;
