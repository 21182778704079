import React from 'react';
import {
  Grid, Card, CardContent, Typography, CardActions, Button,
  useTheme,
} from '@mui/material';
import type { FifaCardProps } from '../../interfaces';

const FifaCard: React.FC<FifaCardProps> = ({ data, selectedFifa, setSelectedFifa, handleStart }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
      <Card
        onClick={() => setSelectedFifa(selectedFifa === data.id ? null : data.id)}
        raised
        sx={{
          width: '95%',
          margin: '10px',
          cursor: 'pointer',
          border: selectedFifa === data.id
            ? `2px solid ${theme.palette.primary.main}`
            : `2px solid ${theme.palette.secondary.main}`,
        }}
      >
        <CardContent>
          <Typography variant="h4" component="h2" align="center">
            {data.name}
          </Typography>
          <Typography variant="h6" component="p" align="center" sx={{ marginTop: '1rem' }}>
            {`${data.team_count} teams`}
          </Typography>
          <Typography variant="h6" component="p" align="center">
            {`${data.player_count} players`}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleStart}
            sx={{
              color: '#fff',
              marginBottom: '10px',
            }}
            disabled={selectedFifa !== data.id}
          >
            Start
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default FifaCard;
