import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Tooltip } from '@mui/material';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import { useRecordContext, useNotify, useDataProvider, useRedirect, type RaRecord } from 'react-admin';
import EditSeasonDialog from './EditSeasonDialog';
import NotablePlayersDialog from './NotablePlayersDialog';
import type { CareerTableActionsProps } from '../../interfaces';

const CareerTableActions: React.FC<CareerTableActionsProps> = ({ fifa, setRefresh }) => {
  const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
  const [openNotablePlayersDialog, setOpenNotablePlayersDialog] = React.useState<boolean>(false);

  const record = useRecordContext() ?? { id: 0 };
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const deleteManagedSeason = async (): Promise<RaRecord> => {
    const id = get(record, ['id'], 0);
    const data = await dataProvider.delete('managed_season', { id, previousData: record });
    return data.data;
  };

  const handleDelete = (): void => {
    deleteManagedSeason().then((resp: RaRecord) => {
      setRefresh(true);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server error', { type: 'error' });
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {record.squadId !== null ? (
          <Tooltip title="Squad">
            <IconButton color="primary" size="small" onClick={() => redirect('show', 'squad', record.squadId)}>
              <Diversity3Icon />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="Notable Players">
          <IconButton color="primary" size="small" onClick={() => setOpenNotablePlayersDialog(true)}>
            <DirectionsRunIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton color="primary" size="small" onClick={() => setOpenEditDialog(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete} size="small" color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {openEditDialog
        ? <EditSeasonDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          seasonData={record}
          fifa={fifa}
          setRefresh={setRefresh}
        />
        : null
      }
      {openNotablePlayersDialog ? (
        <NotablePlayersDialog
          open={openNotablePlayersDialog}
          setOpen={setOpenNotablePlayersDialog}
          managedSeasonId={get(record, ['id'], 0)}
          fifaId={fifa}
        />
      ) : null}
    </>
  );
};

export default CareerTableActions;
