import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PlayerCreate from './Create';
import PlayerEdit from './Edit';
import PlayerList from './List';
import PlayerShow from './Show';

const Player = {
  name: 'player',
  icon: DirectionsRunIcon,
  list: PlayerList,
  show: PlayerShow,
  edit: PlayerEdit,
  create: PlayerCreate,
};

export default Player;
