import React from 'react';
import { Layout, type LayoutProps } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import CustomMenu from './CustomMenu';

const CustomLayout: React.FC<LayoutProps> = (props) => {
  return (
    <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />
  );
};

export default CustomLayout;
