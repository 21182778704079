import React from 'react';
import {
  Dashboard, Groups, ExpandLess, ExpandMore, Person,
  DirectionsRun, Dialpad, Place, Diversity3, Work,
  Engineering, EmojiEvents, SportsEsports, CalendarMonth,
} from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, useTheme, useMediaQuery, type Theme } from '@mui/material';
import { Menu, useRedirect, type MenuProps } from 'react-admin';
import { useLocation } from 'react-router-dom';
import SubMenuItem from './SubMenuItem';
import type { MenuItem as MenuItemInterface } from '../../interfaces';

const dataItems: MenuItemInterface[] = [
  {
    name: 'Users',
    resource: 'user',
    icon: <Person />,
  },
  {
    name: 'Players',
    resource: 'player',
    icon: <DirectionsRun />,
  },
  {
    name: 'Teams',
    resource: 'team',
    icon: <Groups />,
  },
  {
    name: 'Formations',
    resource: 'formation',
    icon: <Dialpad />,
  },
  {
    name: 'Positions',
    resource: 'position',
    icon: <Place />,
  },
  {
    name: 'FIFAs',
    resource: 'fifa',
    icon: <SportsEsports />,
  },
];

const careerItems: MenuItemInterface[] = [
  {
    name: 'Career',
    resource: 'career',
    icon: <Work />,
  },
  {
    name: 'Managers',
    resource: 'manager',
    icon: <Engineering />,
  },
  {
    name: 'Trophies',
    resource: 'trophy',
    icon: <EmojiEvents />,
  },
  {
    name: 'Seasons',
    resource: 'season',
    icon: <CalendarMonth />,
  },
];

const CustomMenu: React.FC<MenuProps> = (props) => {
  const [openData, setOpenData] = React.useState<boolean>(false);
  const [openCareer, setOpenCareer] = React.useState<boolean>(false);

  const theme = useTheme();
  const redirect = useRedirect();
  const location = useLocation();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Menu {...props}>
      <MenuItem
        onClick={() => redirect('/')}
        sx={{
          backgroundColor: location.pathname === '/' ? theme.palette.primary.contrastText : '',
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon
          sx={{
            color: location.pathname === '/' ? theme.palette.primary.main : '',
          }}
        >
          <Dashboard />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          sx={{
            color: location.pathname === '/' ? theme.palette.primary.main : '',
          }}
        />
      </MenuItem>
      <MenuItem
        onClick={() => setOpenData(!openData)}
        sx={{
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon>
          <Groups />
        </ListItemIcon>
        <ListItemText primary="Data" />
        {openData
          ? <ExpandLess />
          : <ExpandMore />
        }
      </MenuItem>
      <SubMenuItem items={dataItems} open={openData} />
      <MenuItem
        onClick={() => setOpenCareer(!openCareer)}
        sx={{
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Career" />
        {openCareer
          ? <ExpandLess />
          : <ExpandMore />
        }
      </MenuItem>
      <SubMenuItem items={careerItems} open={openCareer} />
      <MenuItem
        onClick={() => redirect('squad', 'list')}
        sx={{
          backgroundColor: location.pathname === '/squad' ? theme.palette.primary.contrastText : '',
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon
          sx={{
            color: location.pathname === '/squad' ? theme.palette.primary.main : '',
          }}
        >
          <Diversity3 />
        </ListItemIcon>
        <ListItemText
          primary="Squads"
          sx={{
            color: location.pathname === '/squad' ? theme.palette.primary.main : '',
          }}
        />
      </MenuItem>
    </Menu>
  );
};

export default CustomMenu;
