import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import CustomLayout from './components/utils/CustomLayout';
import ComingSoonPage from './layout/ComingSoonPage';
import Dashboard from './layout/Dashboard';
import LoginPage from './layout/LoginPage';
import SquadBuilder from './layout/SquadBuilder';
import authProvider from './providers/AuthProvider';
import dataProvider from './providers/DataProvider';
import Career from './resources/Career';
import Fifa from './resources/Fifa';
import Formation from './resources/Formation';
import Manager from './resources/Manager';
import Player from './resources/Player';
import Position from './resources/Position';
import Season from './resources/Season';
import Squad from './resources/Squad';
import Team from './resources/Team';
import Trophy from './resources/Trophy';
import User from './resources/User';
import Theme from './utils/Theme';

const App: React.FC = () => {
  const comingSoon = process.env.REACT_APP_COMING_SOON ?? '';
  return (
    <>
      {comingSoon.length > 0 && comingSoon === 'true' ? (
        <ComingSoonPage />
      ) : (
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          layout={CustomLayout}
          loginPage={LoginPage}
          dashboard={Dashboard}
          theme={Theme}
        >
          <Resource {...User} />
          <Resource {...Player} />
          <Resource {...Team} />
          <Resource {...Position} />
          <Resource {...Formation} />
          <Resource {...Squad} />
          <Resource {...Trophy} />
          <Resource {...Season} />
          <Resource {...Manager} />
          <Resource {...Career} />
          <Resource {...Fifa} />
          <Resource name="managed_season" />
          <Resource name="notable_player" />
          <CustomRoutes>
            <Route path="/squad-builder" element={<SquadBuilder />} />
          </CustomRoutes>
        </Admin>
      )}
    </>
  );
};

export default App;
