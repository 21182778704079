import React from 'react';
import { Chip } from '@mui/material';
import type { RaRecord } from 'react-admin';
import type { TrophyChipFieldProps } from '../../interfaces';

const TrophyChipField: React.FC<TrophyChipFieldProps> = ({ id, trophies }) => {
  const [name, setName] = React.useState<string>('');

  React.useEffect(() => {
    const filtered = trophies.filter((trophy: RaRecord) => trophy.id === id);
    if (filtered.length > 0) setName(filtered[0].name);
  }, []);

  return (
    <>
      {name.length > 0
        ? <Chip
          label={name}
          color="primary"
          clickable={false}
          sx={{
            color: '#fff',
          }}
        />
        : null
      }
    </>
  );
};

export default TrophyChipField;
