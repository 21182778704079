import React from 'react';
import { FormControl, InputLabel, Select, Box, MenuItem, type SelectChangeEvent } from '@mui/material';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import { useDataProvider, useNotify, type RaRecord, type Identifier } from 'react-admin';
import { useController } from 'react-hook-form';
import type { SeasonTrophiesInputProps } from '../../interfaces';
import TrophyChipField from '../fields/TrophyChipField';

const SeasonTrophiesInput: React.FC<SeasonTrophiesInputProps> = ({ source, seasonData, fifa, setSameTrophies }) => {
  const [ids, setIds] = React.useState<Identifier[]>([]);
  const [defaultIds, setDefaultIds] = React.useState<Identifier[]>([]);
  const [trophies, setTrophies] = React.useState<RaRecord[]>([]);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const input = useController({ name: 'trophyIds' }).field;

  const handleChange = (event: SelectChangeEvent<typeof ids>): void => {
    const { target: { value } } = event;
    setIds(value as Identifier[]);
    input.onChange(value);
  };

  const getIds = async (): Promise<Identifier[]> => {
    const managedSeasonId = get(seasonData, source, 0);
    const data = await dataProvider.getList('season_trophy', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });
    const filtered = data.data.filter((item: RaRecord) => item.managedSeasonId === managedSeasonId);
    const onlyIds = filtered.map((item: RaRecord) => item.trophyId);
    return onlyIds;
  };

  const getTrophies = async (): Promise<RaRecord[]> => {
    const data = await dataProvider.getList('trophy', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: { fifaId: fifa },
    });
    return data.data;
  };

  React.useEffect(() => {
    getIds().then((resp: Identifier[]) => {
      setIds(resp);
      setDefaultIds(resp);
      input.onChange(resp);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server error', { type: 'error' });
    });
    getTrophies().then((resp: RaRecord[]) => {
      setTrophies(resp);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server error', { type: 'error' });
    });
  }, []);

  React.useEffect(() => {
    if (ids.length === defaultIds.length) {
      setSameTrophies(ids.every((element: Identifier) => defaultIds.includes(element)));
    } else setSameTrophies(false);
  }, [ids]);

  return (
    <FormControl variant="filled" sx={{ width: 219, marginBottom: '2rem' }}>
      <InputLabel>Trophies</InputLabel>
      <Select
        multiple
        value={ids}
        onChange={handleChange}
        renderValue={(selected: Identifier[]) => {
          return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin: '10px 0px' }}>
            {selected.map((value: Identifier) => {
              return <TrophyChipField
                key={value}
                id={value}
                trophies={trophies}
              />;
            })}
          </Box>;
        }}
      >
        {trophies.map((trophy: RaRecord, index: number) => {
          return <MenuItem key={index} value={trophy.id}>
            {trophy.name}
          </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default SeasonTrophiesInput;
