import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Box, Divider } from '@mui/material';
import type { SquadBuilderActionsProps } from '../../interfaces';

const SquadBuilderActions: React.FC<SquadBuilderActionsProps> = ({
  level,
  setLevel,
  onSave,
  disabled,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Divider variant="middle" color="primary" />
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem 0px' }}>
        <Button
          onClick={() => setLevel(level - 1)}
          startIcon={<ArrowBackIosIcon />}
          sx={{
            marginRight: '1rem',
          }}
        >
          Back
        </Button>
        {level === 3
          ? <Button
            variant="contained"
            onClick={onSave}
            startIcon={<SaveIcon />}
            disabled={disabled}
            sx={{
              color: '#fff',
            }}
          >
            Save Squad
          </Button>
          : null
        }
      </Box>
    </Box>
  );
};

export default SquadBuilderActions;
