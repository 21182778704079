import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, Paper, Typography,
  Box, Avatar, useMediaQuery, type Theme,
} from '@mui/material';
import { useRedirect, type RaRecord } from 'react-admin';
import type { SquadPlayersSubsDialogProps, Player } from '../../../interfaces';
import { buildFifaIndexUrl } from '../../../utils';

const SquadPlayersSubsDialog: React.FC<SquadPlayersSubsDialogProps> = ({
  open,
  setOpen,
  playersInSquad,
  playerList,
  fifa,
}) => {
  const [subs, setSubs] = React.useState<RaRecord[]>([]);
  const redirect = useRedirect();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const filtered = playersInSquad.filter((player: RaRecord) => player.sub === 1);
    const onlySubs = playerList.filter((player: Player) =>
      filtered.some((playerInSquad: RaRecord) => playerInSquad.playerId === player.id),
    );
    setSubs(onlySubs);
  }, [playersInSquad]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Subs</DialogTitle>
        <DialogContent>
          <Paper sx={{ backgroundColor: '#d6d6c2', padding: '10px' }}>
            {subs.map((sub: RaRecord, index: number) => {
              return <Paper
                data-id={sub.id}
                elevation={0}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px',
                  width: isSmall ? 'auto' : '50%',
                  padding: '10px',
                }}
                key={index}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    src={buildFifaIndexUrl(fifa, sub.fifaIndexId, 'players')}
                    onClick={() => redirect('show', 'player', sub.id)}
                    sx={{
                      width: 40,
                      height: 40,
                      margin: 'auto',
                      cursor: 'pointer',
                    }}
                  />
                  <Typography
                    onClick={() => redirect('show', 'player', sub.id)}
                    sx={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                  >
                    {`${sub.firstName} ${sub.lastName}`}
                  </Typography>
                </Box>
              </Paper>;
            })}
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SquadPlayersSubsDialog;
