import React from 'react';
import { Chip, useTheme } from '@mui/material';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import {
  useRecordContext, useDataProvider, useNotify,
  type RaRecord, type Identifier,
} from 'react-admin';
import type { TrophyChipProps } from '../../interfaces';

const TrophyChip: React.FC<TrophyChipProps> = ({ source }) => {
  const [trophyName, setTrophyName] = React.useState<string>('');

  const theme = useTheme();
  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const getTrophy = async (id: Identifier): Promise<RaRecord> => {
    const data = await dataProvider.getOne('trophy', { id });
    return data.data;
  };

  React.useEffect(() => {
    if (record !== undefined) {
      const id = get(record, source, 0);
      if (id > 0) {
        getTrophy(id).then((resp: RaRecord) => {
          setTrophyName(resp.name);
        }).catch((err: AxiosError) => {
          if (err.response?.status === 500) notify('Server Error', { type: 'error' });
        });
      }
    }
  }, []);

  return (
    <>
      {trophyName.length > 0
        ? <Chip
          label={trophyName}
          clickable={false}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            marginLeft: '5px',
          }}
        />
        : null
      }
    </>
  );
};

export default TrophyChip;
