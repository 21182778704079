import React from 'react';
import { useMediaQuery, type Theme } from '@mui/material';
import {
  List, Datagrid, SimpleList, TextField, ReferenceField,
  SearchInput, SelectInput, ReferenceInput, type ListProps,
} from 'react-admin';
import CustomListActions from '../../components/utils/CustomListActions';

const squadListFilters = [
  <SearchInput
    source="name"
    variant="filled"
    key="name"
    alwaysOn
  />,
  <ReferenceInput
    source="fifaId"
    reference="fifa"
    key="fifaId"
    sort={{
      field: 'name',
      order: 'ASC',
    }}
    alwaysOn
  >
    <SelectInput
      label="FIFA"
      variant="filled"
      optionValue="id"
      optionText="name"
    />
  </ReferenceInput>,
];

const squadListFiltersSmall = [
  <SearchInput
    source="name"
    variant="filled"
    key="name"
    alwaysOn={false}
    sx={{
      marginLeft: '5px',
    }}
  />,
  <ReferenceInput
    source="fifaId"
    reference="fifa"
    key="fifaId"
    sort={{
      field: 'name',
      order: 'ASC',
    }}
    alwaysOn={false}
  >
    <SelectInput
      label="FIFA"
      variant="filled"
      optionValue="id"
      optionText="name"
      sx={{
        width: 219,
        marginLeft: '5px',
      }}
    />
  </ReferenceInput>,
];

const SquadList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomListActions hasFilters createSquad resource="squad" />}
      filters={!isSmall ? squadListFilters : squadListFiltersSmall}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall
        ? <Datagrid rowClick="show">
          <TextField source="name" />
          <ReferenceField source="formationId" reference="formation" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
        : <SimpleList
          primaryText={data => data.name}
          linkType="show"
        />
      }
    </List>
  );
};

export default SquadList;
