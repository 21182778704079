import React from 'react';
import { useMediaQuery, type Theme } from '@mui/material';
import { List, Datagrid, SimpleList, TextField, NumberField, SearchInput, type ListProps } from 'react-admin';
import CustomListActions from '../../components/utils/CustomListActions';

const userListFilters = [
  <SearchInput
    source="login"
    variant="filled"
    key="login"
    alwaysOn
  />,
];

const userListFiltersSmall = [
  <SearchInput
    source="login"
    variant="filled"
    key="login"
    alwaysOn={false}
    sx={{
      marginLeft: '5px',
    }}
  />,
];

const UserList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomListActions hasFilters resource="user" />}
      filters={!isSmall ? userListFilters : userListFiltersSmall}
      {...props}
    >
      {!isSmall
        ? <Datagrid rowClick="show">
          <NumberField source="id" />
          <TextField source="login" />
        </Datagrid>
        : <SimpleList
          primaryText={data => data.login}
          linkType="show"
        />
      }
    </List>
  );
};

export default UserList;
