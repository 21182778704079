import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Card, CardContent, Box, Avatar, Typography, Badge,
  IconButton, useTheme, useMediaQuery, type Theme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import { useDataProvider, useNotify, type RaRecord } from 'react-admin';
import SelectPlayerDialog from './SelectPlayerDialog';
import type { SquadPlayerCardProps, Team } from '../../interfaces';
import { buildFifaIndexUrl } from '../../utils';

const SquadPlayerCard: React.FC<SquadPlayerCardProps> = ({
  type,
  fifa,
  playersInSquad,
  setPlayersInSquad,
  order,
  playerList,
}) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [selectedPlayer, setSelectedPlayer] = React.useState<RaRecord>();
  const [teamAvatar, setTeamAvatar] = React.useState<string>('');

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const theme = useTheme();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const removePlayers = (): void => {
    const newPlayers = playersInSquad.filter((player: RaRecord) => player.id !== selectedPlayer?.id);
    setPlayersInSquad(newPlayers);
    setSelectedPlayer(undefined);
  };

  const getTeam = async (): Promise<Team> => {
    const teamId = selectedPlayer?.teamId;
    const data = await dataProvider.getOne('team', { id: teamId });
    return data.data;
  };

  /* get team avatar based on selected player */
  React.useEffect(() => {
    if (selectedPlayer !== undefined) {
      getTeam().then((respTeam: Team) => {
        setTeamAvatar(buildFifaIndexUrl(fifa ?? 0, respTeam.fifaIndexId, 'teams'));
      }).catch((err: AxiosError) => {
        if (err.response?.status === 500) notify('Server Error', { type: 'error' });
      });
    }
  }, [selectedPlayer]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        raised
        sx={{
          width: isSmall ? '100%' : '95%',
        }}
      >
        <CardContent>
          {selectedPlayer !== undefined ? <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton size="small" onClick={removePlayers}>
              <ClearIcon sx={{ color: 'red' }} />
            </IconButton>
          </Box> : null}
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {selectedPlayer !== undefined
                ? <Badge
                  badgeContent={
                    <Typography sx={{ color: '#fff' }}>
                      {selectedPlayer.rating}
                    </Typography>
                  }
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  color="primary"
                >
                  <Avatar
                    src={buildFifaIndexUrl(fifa ?? 0, selectedPlayer.fifaIndexId, 'players')}
                    sx={{
                      width: 70,
                      height: 70,
                      margin: 'auto',
                    }}
                  />
                </Badge>
                : <Avatar
                  onClick={() => setOpenDialog(true)}
                  sx={{
                    width: 70,
                    height: 70,
                    backgroundColor: theme.palette.primary.main,
                    color: '#fff',
                    cursor: 'pointer',
                    margin: 'auto',
                  }}
                >
                  {type.toUpperCase()}
                </Avatar>
              }
            </Box>
            {selectedPlayer !== undefined
              ? <Box sx={{ marginTop: '1rem' }}>
                <Typography align="center">
                  {`${selectedPlayer.firstName} ${selectedPlayer.lastName}`}
                </Typography>
                <Avatar
                  src={teamAvatar}
                  sx={{
                    width: 54,
                    height: 54,
                    margin: 'auto',
                  }}
                />
              </Box>
              : null
            }
          </Box>
        </CardContent>
      </Card>
      {openDialog
        ? <SelectPlayerDialog
          open={openDialog}
          setOpen={setOpenDialog}
          setSelectedPlayer={setSelectedPlayer}
          playersInSquad={playersInSquad}
          setPlayersInSquad={setPlayersInSquad}
          order={order}
          playerList={playerList}
        />
        : null
      }
    </Box>
  );
};

export default SquadPlayerCard;
