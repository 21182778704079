import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SeasonCreate from './Create';
import SeasonEdit from './Edit';
import SeasonList from './List';

const Season = {
  name: 'season',
  icon: CalendarMonthIcon,
  list: SeasonList,
  edit: SeasonEdit,
  create: SeasonCreate,
};

export default Season;
