import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import FifaCreate from './Create';
import FifaList from './List';

const Fifa = {
  name: 'fifa',
  icon: SportsEsportsIcon,
  list: FifaList,
  create: FifaCreate,
};

export default Fifa;
