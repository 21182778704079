import React from 'react';
import {
  Create, SimpleForm, TextInput, ReferenceInput, SelectInput,
  required, type CreateProps,
} from 'react-admin';

const PositionCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create redirect="show" sx={{ marginBottom: '1rem' }} {...props}>
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <ReferenceInput
          source="positionGroupId"
          reference="position_group"
        >
          <SelectInput
            source="name"
            variant="filled"
            optionText="name"
            optionValue="id"
            validate={required()}
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default PositionCreate;
