import React from 'react';
import { Create, SimpleForm, TextInput, required, type CreateProps } from 'react-admin';

const UserCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create redirect="show" sx={{ marginBottom: '1rem' }} {...props}>
      <SimpleForm spacing={1}>
        <TextInput source="login" variant="filled" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
