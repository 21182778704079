import React from 'react';
import {
  Show, TabbedShowLayout, TextField, NumberField,
  ReferenceField, type ShowProps,
} from 'react-admin';
import EmbeddedList from '../../components/fields/EmbeddedList';
import FifaIndexUrlField from '../../components/fields/FifaIndexUrlField';
import ImageField from '../../components/fields/ImageField';
import CustomShowTitle from '../../components/utils/CustomShowTitle';

const TeamShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<CustomShowTitle resource="team" />} sx={{ marginBottom: '1rem' }}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <TextField source="name" />
          <ImageField source="fifaIndexId" alt="club-badge" type="teams" label="Badge" />
          <NumberField source="budget" label="Budget (€)" />
          <NumberField source="fifaIndexId" />
          <ReferenceField source="rivalTeamId" reference="team" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="squadId" reference="squad" link="show">
            <TextField source="name" />
          </ReferenceField>
          <FifaIndexUrlField label="Fifa Index Page" type="team" />
          <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Players">
          <EmbeddedList
            resource="player"
            orderBy="rating"
            filterBy="teamId"
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TeamShow;
