import React, { type SyntheticEvent } from 'react';
import { FormControl, Autocomplete, TextField } from '@mui/material';
import type { AxiosError } from 'axios';
import { useRecordContext, useDataProvider, useNotify } from 'react-admin';
import { useController } from 'react-hook-form';
import type { RivalTeamInputProps, Team } from '../../interfaces';
import SmallLoader from '../utils/SmallLoader';

const RivalTeamInput: React.FC<RivalTeamInputProps> = ({ source, label, view, fifa }) => {
  const input = useController({ name: source }).field;
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [choices, setChoices] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedTeamName, setSelectedTeamName] = React.useState<string>('Empty');

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const record = useRecordContext() ?? { id: 0 };

  const handleInput = (event: SyntheticEvent, newValue: string | null): void => {
    if (newValue !== null) {
      setSelectedTeamName(newValue);
      const filtered = teams.filter((team: Team) => team.name === newValue);
      input.onChange(filtered.length === 0 ? null : filtered[0].id);
    }
  };

  const getTeams = async (): Promise<Team[]> => {
    const teams = await dataProvider.getList('team', {
      pagination: { page: 1, perPage: 10000000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });
    if (view === 'edit') {
      const filtered = teams.data.filter((team: Team) => team.id !== record.id);
      const filteredFifa = filtered.filter((team: Team) => team.fifaId === fifa);
      return filteredFifa;
    } else {
      if (fifa !== undefined) {
        const filtered = teams.data.filter((team: Team) => team.fifaId === fifa);
        return filtered;
      } else return teams.data;
    }
  };

  React.useEffect(() => {
    input.onChange(null);
    setSelectedTeamName('Empty');
    getTeams().then((resp: Team[]) => {
      setTeams(resp);
      const onlyNames = resp.map((team: Team) => team.name);
      const withEmpty = [...onlyNames, 'Empty'];
      setChoices(withEmpty);
      if (input.value !== undefined || input.value !== null) {
        const currentTeam = resp.filter((team: Team) => team.id === input.value);
        if (currentTeam.length > 0) setSelectedTeamName(currentTeam[0].name);
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  }, [fifa]);

  return (
    !loading
      ? <FormControl
        sx={{
          width: 219,
          height: '71.91px',
          marginBottom: '1rem !important',
        }}
      >
        <Autocomplete
          value={selectedTeamName}
          onChange={handleInput}
          options={choices}
          renderInput={(params) => <TextField {...params} label={label} variant="filled" />}
        />
      </FormControl>
      : <SmallLoader />
  );
};

export default RivalTeamInput;
