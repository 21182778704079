import React from 'react';
import { Create, SimpleForm, TextInput, required, type CreateProps } from 'react-admin';

const FifaCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create
      redirect="list"
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput
          source="name"
          variant="filled"
          validate={required()}
          sx={{
            width: 219,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default FifaCreate;
