import React from 'react';
import { useRecordContext } from 'react-admin';
import type { CustomEditTitleProps } from '../../interfaces';

const CustomEditTitle: React.FC<CustomEditTitleProps> = ({ resource }) => {
  const [title, setTitle] = React.useState<string>('');
  const record = useRecordContext();

  React.useEffect(() => {
    const resourceUpper: string = `${resource[0].toUpperCase()}${resource.slice(1)}`;
    if (record !== undefined) {
      if (resource === 'player' || resource === 'manager') {
        const { firstName, lastName } = record;
        setTitle(`Edit ${resourceUpper} ${firstName} ${lastName}`);
      } else if (resource === 'user') {
        const { login } = record;
        setTitle(`Edit ${resourceUpper} ${login}`);
      } else {
        const { name } = record;
        setTitle(`Edit ${resourceUpper} ${name}`);
      }
    }
  }, [record]);

  if (record === undefined) return null;
  return (
    <>
      {title}
    </>
  );
};

export default CustomEditTitle;
