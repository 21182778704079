import React from 'react';
import {
  Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
  required, type EditProps,
} from 'react-admin';
import CustomEditTitle from '../../components/utils/CustomEditTitle';

const SquadEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      redirect="show"
      title={<CustomEditTitle resource="squad" />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <ReferenceInput
          source="formationId"
          reference="formation"
        >
          <SelectInput
            source="name"
            variant="filled"
            optionText="name"
            optionValue="id"
            validate={required()}
            sx={{
              width: 219,
            }}
            disabled
          />
        </ReferenceInput>
        <ReferenceInput source="fifaId" reference="fifa">
          <SelectInput
            label="FIFA"
            variant="filled"
            validate={required()}
            optionText="name"
            optionValue="id"
            disabled
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default SquadEdit;
