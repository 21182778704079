import React from 'react';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import InputMask from 'react-input-mask';
import type { SeasonInputProps } from '../../interfaces';
import { isValidYearFormat } from '../../utils';

const SeasonInput: React.FC<SeasonInputProps> = ({ source }) => {
  const input = useController({ name: source }).field;
  const [value, setValue] = React.useState<string>(input.value ?? '');
  const [error, setError] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;
    setError(inputValue.length === 9 && !isValidYearFormat(inputValue));
    setValue(inputValue);
    input.onChange(inputValue);
  };

  return (
    <InputMask
      mask="9999/9999"
      value={value}
      onChange={handleChange}
    >
      <TextField
        label="Season *"
        variant="filled"
        value={value}
        onChange={handleChange}
        error={error}
        helperText={error ? 'Format should be YYYY/YYYY+1' : ''}
        sx={{
          marginBottom: error ? '0px' : '1.5rem !important',
          width: 219,
        }}
      />
    </InputMask>
  );
};

export default SeasonInput;
