import React from 'react';
import { useMediaQuery, type Theme } from '@mui/material';
import {
  List, Datagrid, SimpleList, TextField, SearchInput,
  type ListProps, type RaRecord,
} from 'react-admin';
import CustomListActions from '../../components/utils/CustomListActions';

const fifaListFilters = [
  <SearchInput
    source="name"
    variant="filled"
    key="name"
    alwaysOn
  />,
];

const fifaListFiltersSmall = [
  <SearchInput
    source="name"
    variant="filled"
    key="name"
    sx={{
      width: 219,
      marginLeft: '5px',
    }}
    alwaysOn={false}
  />,
];

const FifaList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomListActions hasFilters resource="fifa" />}
      filters={isSmall ? fifaListFiltersSmall : fifaListFilters}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall
        ? <Datagrid
          rowClick="edit"
          bulkActionButtons={<></>}
          isRowSelectable={(record: RaRecord) => false}
        >
          <TextField source="name" />
        </Datagrid>
        : <SimpleList
          primaryText={(data: RaRecord) => data.name}
          linkType="edit"
        />
      }
    </List>
  );
};

export default FifaList;
