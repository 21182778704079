import React from 'react';
import type { AxiosError } from 'axios';
import {
  Show, TabbedShowLayout, TextField, ReferenceField,
  FunctionField, useDataProvider, useNotify, type ShowProps,
  type RaRecord,
} from 'react-admin';
import CareerHistoryTable from '../../components/career/CareerHistoryTable';
import CustomShowActions from '../../components/utils/CustomShowActions';
import CustomShowTitle from '../../components/utils/CustomShowTitle';

const CareerShow: React.FC<ShowProps> = (props) => {
  const [managedSeasons, setManagedSeason] = React.useState<RaRecord[]>([]);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const getCareerData = async (): Promise<RaRecord[]> => {
    const data = await dataProvider.getList('managed_season', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'seasonId', order: 'ASC' },
      filter: {},
    });
    return data.data;
  };

  React.useEffect(() => {
    getCareerData()
      .then((resp: RaRecord[]) => {
        setManagedSeason(resp);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 500) notify('Server error', { type: 'error' });
      });
  }, []);

  React.useEffect(() => {
    if (refresh) {
      getCareerData()
        .then((resp: RaRecord[]) => {
          setManagedSeason(resp);
          setRefresh(false);
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 500) notify('Server error', { type: 'error' });
        });
    }
  }, [refresh]);

  return (
    <Show
      title={<CustomShowTitle resource="career" />}
      actions={<CustomShowActions resource="career" hasCareerButton setRefreshSeasons={setRefresh} />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <TextField source="name" />
          <ReferenceField source="managerId" reference="manager" link="show">
            <FunctionField
              source="lastName"
              label="Manager"
              render={(record: RaRecord) => `${record.firstName} ${record.lastName}`}
            />
          </ReferenceField>
          <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
            <TextField source="name"/>
          </ReferenceField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="career history" path="career-history">
          <CareerHistoryTable
            managedSeasons={managedSeasons}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CareerShow;
