import PersonIcon from '@mui/icons-material/Person';
import UserCreate from './Create';
import UserEdit from './Edit';
import UserList from './List';
import UserShow from './Show';

const User = {
  name: 'user',
  icon: PersonIcon,
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
};

export default User;
