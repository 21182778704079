import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Box, Tooltip } from '@mui/material';
import type { AxiosError } from 'axios';
import { get } from 'lodash';
import { useRecordContext, useDataProvider, useNotify, type RaRecord } from 'react-admin';
import EditNotablePlayerForm from './EditNotablePlayerForm';
import type { NotablePlayerActionsProps } from '../../interfaces';

const NotablePlayerActions: React.FC<NotablePlayerActionsProps> = ({ label, setRefresh }) => {
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const record = useRecordContext() ?? { id: 0 };

  const deletePlayer = async (): Promise<RaRecord> => {
    const id = get(record, ['id'], 0);
    const data = await dataProvider.delete('notable_player', { id, previousData: record });
    return data.data;
  };

  const handleDelete = (): void => {
    deletePlayer()
      .then((resp: RaRecord) => {
        notify('Notable player deleted', { type: 'success' });
        setRefresh(true);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 500) notify('Server error', { type: 'error' });
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Edit">
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </Box>
      {open ? (
        <EditNotablePlayerForm
          open={open}
          setOpen={setOpen}
          record={record}
          setRefresh={setRefresh}
        />
      ) : null}
    </>
  );
};

export default NotablePlayerActions;
