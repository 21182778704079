import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, ReferenceField, type ShowProps } from 'react-admin';
import FifaIndexUrlField from '../../components/fields/FifaIndexUrlField';
import ImageField from '../../components/fields/ImageField';
import CustomShowActions from '../../components/utils/CustomShowActions';
import CustomShowTitle from '../../components/utils/CustomShowTitle';

const PlayerShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<CustomShowTitle resource="player" />}
      actions={<CustomShowActions resource="player" hasFavouriteButton />}
      sx={{
        marginBottom: '1rem',
      }}
    >
      <SimpleShowLayout>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <ImageField source="fifaIndexId" alt="game-face" type="players" label="Game face" />
        <ReferenceField source="teamId" reference="team" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="age" />
        <NumberField source="fifaIndexId" />
        <ReferenceField source="positionId" reference="position" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="rating" />
        <NumberField source="rating_potential" label="Potentional rating" />
        <FifaIndexUrlField label="Fifa Index Page" type="player" />
        <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default PlayerShow;
