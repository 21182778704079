import React from 'react';
import { useRecordContext } from 'react-admin';
import type { CustomShowTitleProps } from '../../interfaces';

const CustomShowTitle: React.FC<CustomShowTitleProps> = ({ resource }) => {
  const [title, setTitle] = React.useState<string>('');
  const record = useRecordContext();

  React.useEffect(() => {
    if (record !== undefined) {
      if (resource === 'player' || resource === 'manager') {
        const { firstName, lastName } = record;
        setTitle(`${resource[0].toUpperCase() + resource.slice(1)} ${firstName} ${lastName}`);
      } else if (resource === 'user') {
        const { login } = record;
        setTitle(`${resource[0].toUpperCase() + resource.slice(1)} ${login}`);
      } else {
        const { name } = record;
        setTitle(`${resource[0].toUpperCase() + resource.slice(1)} ${name}`);
      }
    }
  }, [record]);

  if (record === undefined) return null;
  return (
    <>
      {title}
    </>
  );
};

export default CustomShowTitle;
