import React from 'react';
import { Collapse, MenuItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import type { SubMenuItemProps, MenuItem as MenuItemInterface } from '../../interfaces';

const SubMenuItem: React.FC<SubMenuItemProps> = ({ open, items }) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const location = useLocation();

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {items.map((item: MenuItemInterface, index: number) => {
        return <MenuItem
          onClick={() => redirect('list', item.resource)}
          sx={{
            backgroundColor: location.pathname === `/${item.resource}` ? theme.palette.primary.contrastText : '',
            marginLeft: '10px',
          }}
          key={index}
        >
          <ListItemIcon
            sx={{ color: location.pathname === `/${item.resource}` ? theme.palette.primary.main : '' }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.name}
            sx={{
              color: location.pathname === `/${item.resource}` ? theme.palette.primary.main : '',
            }}
          />
        </MenuItem>;
      })}
    </Collapse>
  );
};

export default SubMenuItem;
