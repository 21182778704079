import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, type CreateProps } from 'react-admin';
import type { Formation, FormationErrors } from '../../interfaces';

const FormationCreate: React.FC<CreateProps> = (props) => {
  const validateForm = (values: Partial<Formation>): FormationErrors => {
    const errors: FormationErrors = {};
    const { name, defenders, midfielders, attackers, layout } = values;
    if (defenders !== null && midfielders !== null && attackers !== null) {
      if (defenders !== undefined && midfielders !== undefined && attackers !== undefined) {
        const totalPlayers: number = defenders + midfielders + attackers;
        if (totalPlayers !== 10) {
          errors.defenders = 'Total amount of players is incorrect.';
          errors.midfielders = 'Total amount of players is incorrect.';
          errors.attackers = 'Total amount of players is incorrect.';
        }
      }
    }
    if (name === null) errors.name = 'Required';
    if (layout === null) errors.layout = 'Required';
    if (defenders === null) errors.defenders = 'Required';
    if (midfielders === null) errors.midfielders = 'Required';
    if (attackers === null) errors.attackers = 'Required';
    return errors;
  };

  return (
    <Create redirect="show" sx={{ marginBottom: '1rem' }} {...props}>
      <SimpleForm validate={validateForm} spacing={1}>
        <TextInput source="name" variant="filled" />
        <NumberInput
          source="defenders"
          variant="filled"
          InputProps={{ inputProps: { min: 0 } }}
        />
        <NumberInput
          source="midfielders"
          variant="filled"
          InputProps={{ inputProps: { min: 0 } }}
        />
        <NumberInput
          source="attackers"
          variant="filled"
          InputProps={{ inputProps: { min: 0 } }}
        />
        <TextInput source="layout" variant="filled" helperText="E.g. 4-1-2-3" />
      </SimpleForm>
    </Create>
  );
};

export default FormationCreate;
