import React from 'react';
import { Link } from '@mui/material';
import type { AxiosError } from 'axios';
import { get } from 'lodash';
import {
  useRecordContext, useDataProvider, useNotify, type RaRecord,
  type Identifier,
} from 'react-admin';
import type { FifaIndexUrlFieldProps } from '../../interfaces';

const fifaIndexUrl = process.env.REACT_APP_FIFA_INDEX_URL ?? '';

const FifaIndexUrlField: React.FC<FifaIndexUrlFieldProps> = ({ label, type }) => {
  const [url, setUrl] = React.useState<string>('');
  const [fullName, setFullName] = React.useState<string>('');

  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const getFifa = async (id: Identifier): Promise<RaRecord> => {
    const data = await dataProvider.getOne('fifa', { id });
    return data.data;
  };

  const formatName = (name: string): string => {
    return name
      .toLowerCase()
      .replace(/['’.,]/g, '')
      .replace(/\s+/g, '-');
  };

  React.useEffect(() => {
    if (record !== undefined) {
      const fifaId: Identifier = get(record, ['fifaId'], 0);
      const fifaIndexId: number = get(record, ['fifaIndexId'], 0);
      if (fifaIndexId === 99999999) return;
      if (type === 'player') {
        const firstName: string = get(record, ['firstName'], '');
        const lastName: string = get(record, ['lastName'], '');
        const formattedFirstName = formatName(firstName);
        const formattedLastName = formatName(lastName);
        getFifa(fifaId)
          .then((resp: RaRecord) => {
            const fifaName = resp.name.toLowerCase().replace(/\s+/g, '');
            const finalUrl = `${fifaIndexUrl}/player/${fifaIndexId}/${formattedFirstName}-${formattedLastName}/${fifaName}`;
            setFullName(`${firstName} ${lastName}`);
            setUrl(finalUrl);
          })
          .catch((err: AxiosError) => {
            if (err.response?.status === 500) notify('Server error', { type: 'error' });
          });
      } else {
        const teamName: string = get(record, ['name'], '');
        const formattedName = formatName(teamName);
        getFifa(fifaId)
          .then((resp: RaRecord) => {
            const fifaName = resp.name.toLowerCase().replace(/\s+/g, '');
            const finalUrl = `${fifaIndexUrl}/team/${fifaIndexId}/${formattedName}/${fifaName}`;
            setFullName(teamName);
            setUrl(finalUrl);
          })
          .catch((err: AxiosError) => {
            if (err.response?.status === 500) notify('Server error', { type: 'error' });
          });
      }
    }
  }, []);

  return (
    <>
      {url.length > 0 ? (
        <Link href={url} variant="body2" target="_blank" rel="noopener noreferrer">
          {fullName}
        </Link>
      ) : null}
    </>
  );
};

export default FifaIndexUrlField;
