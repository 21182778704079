import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography,
  IconButton, Button, Divider, useMediaQuery, type Theme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import {
  ListContextProvider, Datagrid, ReferenceField, NumberField, FunctionField,
  SimpleShowLayout, useDataProvider, useNotify, useList, type RaRecord,
} from 'react-admin';
import CreateNotablePlayerForm from './CreateNotablePlayerForm';
import NotablePlayerActions from './NotablePlayerActions';
import type { NotablePlayersDialogProps } from '../../interfaces';
import SmallLoader from '../utils/SmallLoader';

const DatagridExpander: React.FC = () => {
  return (
    <SimpleShowLayout
      direction="row"
      spacing={3}
    >
      <NumberField
        source="appearances"
        label="Apps"
      />
      <NumberField
        source="goals"
        label="G"
      />
      <NumberField
        source="assists"
        label="A"
      />
      <NumberField
        source="yellowCards"
        label="YC"
      />
      <NumberField
        source="redCards"
        label="RC"
      />
      <NumberField
        source="cleanSheets"
        label="CS"
      />
    </SimpleShowLayout>
  );
};

const NotablePlayersDialog: React.FC<NotablePlayersDialogProps> = ({ open, setOpen, managedSeasonId, fifaId }) => {
  const [players, setPlayers] = React.useState<RaRecord[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const notify = useNotify();
  const listContext = useList({ data: players });
  const dataProvider = useDataProvider();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getPlayers = async (): Promise<RaRecord[]> => {
    const data = await dataProvider.getList('notable_player', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: { managedSeasonId },
    });
    return data.data;
  };

  React.useEffect(() => {
    getPlayers()
      .then((resp: RaRecord[]) => {
        setPlayers(resp);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 500) notify('Server error', { type: 'error' });
      });
  }, []);

  React.useEffect(() => {
    if (refresh) {
      getPlayers()
        .then((resp: RaRecord[]) => {
          setPlayers(resp);
          setRefresh(false);
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 500) notify('Server error', { type: 'error' });
        });
    }
  }, [refresh]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">
            {!showForm ? 'Notable players' : 'Create notable player'}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          <>
            {!showForm ? (
              <ListContextProvider value={listContext}>
                <Datagrid
                  resource="notable_player"
                  bulkActionButtons={false}
                  expand={isSmall ? <DatagridExpander /> : undefined}
                >
                  <ReferenceField source="playerId" reference="player" label="Name">
                    <FunctionField
                      source="lastName"
                      render={(record) => `${record.firstName} ${record.lastName}`}
                    />
                  </ReferenceField>
                  {!isSmall && <NumberField
                    source="appearances"
                    label="Apps"
                  />}
                  {!isSmall && <NumberField
                    source="goals"
                    label="G"
                  />}
                  {!isSmall && <NumberField
                    source="assists"
                    label="A"
                  />}
                  {!isSmall && <NumberField
                    source="yellowCards"
                    label="YC"
                  />}
                  {!isSmall && <NumberField
                    source="redCards"
                    label="RC"
                  />}
                  {!isSmall && <NumberField
                    source="cleanSheets"
                    label="CS"
                  />}
                  <NotablePlayerActions
                    label=""
                    setRefresh={setRefresh}
                  />
                </Datagrid>
              </ListContextProvider>
            ) : (
              <CreateNotablePlayerForm
                managedSeasonId={managedSeasonId}
                fifaId={fifaId}
                setRefresh={setRefresh}
              />
            )}
            <Divider />
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SmallLoader />
          </Box>
        )}
      </DialogContent>
      {!loading ? (
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Button
                color="primary"
                startIcon={showForm ? <ListIcon /> : <AddIcon />}
                onClick={() => setShowForm(!showForm)}
              >
                {showForm ? 'List' : 'Add'}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default NotablePlayersDialog;
