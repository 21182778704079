import React from 'react';
import { Box } from '@mui/material';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';
import { type ImageFieldProps } from '../../interfaces';
import { buildFifaIndexUrl } from '../../utils';

const ImageField: React.FC<ImageFieldProps> = ({ source, alt, type }) => {
  const [imgSrc, setImgSrc] = React.useState<string>('');
  const record = useRecordContext();
  const fifaIndexId = get(record, source, 0);
  const fifaVersion: number = get(record, ['fifaId']);

  React.useEffect(() => {
    setImgSrc(buildFifaIndexUrl(fifaVersion, fifaIndexId, type));
  }, [fifaIndexId]);

  return (
    <Box>
      <img src={imgSrc} title={alt} alt={alt} />
    </Box>
  );
};

export default ImageField;
