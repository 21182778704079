import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, type SelectChangeEvent } from '@mui/material';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import { useRecordContext, useDataProvider, useNotify, type RaRecord } from 'react-admin';
import { useController } from 'react-hook-form';
import type { FifaInputProps } from '../../interfaces';

const FifaInput: React.FC<FifaInputProps> = ({ source, label, selectedVal, setSelectedVal, disabled }) => {
  const [fifas, setFifas] = React.useState<RaRecord[]>([]);

  const notify = useNotify();
  const input = useController({ name: source }).field;
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const handleChange = (event: SelectChangeEvent): void => {
    setSelectedVal(event.target.value);
    input.onChange(event.target.value);
  };

  const getFifas = async (): Promise<RaRecord[]> => {
    const data = await dataProvider.getList('fifa', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    return data.data;
  };

  React.useEffect(() => {
    getFifas()
      .then((resp: RaRecord[]) => {
        setFifas(resp);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 500) notify('Server Error', { type: 'error' });
      });
    if (record !== undefined) setSelectedVal(get(record, 'fifaId', ''));
  }, []);

  return (
    <>
      {fifas.length > 0 ? (
        <FormControl
          variant="filled"
          sx={{
            width: 219,
            height: '71.91px',
          }}
        >
          <InputLabel>{`${label} *`}</InputLabel>
          <Select
            value={selectedVal}
            label={`${label} *`}
            onChange={handleChange}
            disabled={disabled}
          >
            {fifas.map((item: RaRecord, index: number) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </>
  );
};

export default FifaInput;
