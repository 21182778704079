import React from 'react';
import { Box, Card, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { type AxiosError } from 'axios';
import {
  TextInput, required, Form, useLogin, useNotify,
  useTranslate, useRedirect,
} from 'react-admin';
import { type LoginFormParams } from '../interfaces';

const useStyles = makeStyles({
  img: {
    margin: 'auto',
    maxWidth: '15rem',
    maxHeight: '6rem',
    display: 'block',
    marginBottom: '1rem',
  },
});

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async (data: LoginFormParams): Promise<void> => {
    await login(data, '/').catch((err: AxiosError) => {
      if (err !== null) {
        const errObj = { ...err };
        if (errObj?.response?.status === 401) notify('Wrong credentials', { type: 'error' });
      }
    });
  };

  React.useEffect(() => {
    const isLogged = localStorage.getItem('logged-squad-builder') ?? '';
    if (isLogged.length > 0 && isLogged === 'true') redirect('/');
  }, []);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-stat',
        backgroundImage: 'radial-gradient(circle at 50% 14em, #00b300 0%, #014d01 60%, #014d01 100%)',
      }}
    >
      <Box>
        <Card raised sx={{ minWidth: '350px', marginTop: '6rem', height: 'auto' }}>
          <CardContent>
            <img src="../images/fifa_main_logo.png" alt="logo" className={classes.img} />
            <Form onSubmit={onSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <TextInput
                  source="username"
                  variant="standard"
                  label={translate('ra.auth.username')}
                  autoComplete="username"
                  validate={required()}
                  sx={{ width: '90%' }}
                  autoFocus
                  fullWidth
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <TextInput
                  source="password"
                  variant="standard"
                  label={translate('ra.auth.password')}
                  type="password"
                  autoComplete="off"
                  validate={required()}
                  sx={{ width: '90%' }}
                  fullWidth
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    color: '#fff',
                    fontWeight: 400,
                  }}
                >
                  {translate('ra.auth.sign_in')}
                </Button>
              </Box>
            </Form>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default LoginPage;
