import React from 'react';
import { Box, useMediaQuery, type Theme } from '@mui/material';
import get from 'lodash/get';
import {
  ArrayField, Datagrid, TextField, ReferenceField, NumberField,
  ReferenceManyField, SingleFieldList, SimpleShowLayout, useShowContext,
  type RaRecord,
} from 'react-admin';
import CareerTableActions from './CareerTableActions';
import TrophyChip from './TrophyChip';
import type { CareerSeasons, CareerHistoryTableProps } from '../../interfaces';
import AvatarField from '../fields/AvatarField';
import SmallLoader from '../utils/SmallLoader';

const DatagridExpander: React.FC = () => {
  return (
    <SimpleShowLayout>
      <ReferenceField source="teamId" reference="team" link="show">
        <AvatarField source="fifaIndexId" type="teams" size={50} label="" />
      </ReferenceField>
      <ReferenceManyField reference="season_trophy" target="managedSeasonId" label="Trophies">
        <SingleFieldList linkType={false}>
          <TrophyChip source="trophyId" />
        </SingleFieldList>
      </ReferenceManyField>
      <NumberField source="finalPosition" />
      <NumberField source="points" />
    </SimpleShowLayout>
  );
};

const CareerHistoryTable: React.FC<CareerHistoryTableProps> = ({ managedSeasons, refresh, setRefresh }) => {
  const [seasons, setSeasons] = React.useState<CareerSeasons>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const { record } = useShowContext();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (record !== undefined) {
      const id = get(record, ['id'], 0);
      if (id > 0) {
        const filtered = managedSeasons.filter((season: RaRecord) => season.careerId === id);
        const seasonsData: CareerSeasons = {
          seasons: filtered,
        };
        setSeasons(seasonsData);
        setLoading(false);
      }
    }
  }, [managedSeasons]);

  return (
    !loading ? (
      <ArrayField record={seasons} source="seasons">
        <Datagrid bulkActionButtons={false} rowClick={false} expand={isSmall ? <DatagridExpander /> : undefined}>
          <ReferenceField source="seasonId" reference="season" link={false}>
            <TextField source="name" />
          </ReferenceField>
          {!isSmall && <ReferenceField source="teamId" reference="team" link="show">
            <AvatarField source="fifaIndexId" type="teams" size={50} label="" />
          </ReferenceField>}
          {!isSmall && <ReferenceManyField reference="season_trophy" target="managedSeasonId" label="Trophies">
            <SingleFieldList linkType={false}>
              <TrophyChip source="trophyId" />
            </SingleFieldList>
          </ReferenceManyField>}
          {!isSmall && <NumberField source="finalPosition" />}
          {!isSmall && <NumberField source="points" />}
          <CareerTableActions fifa={record.fifaId} setRefresh={setRefresh} />
        </Datagrid>
      </ArrayField>
    ) : (
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '2rem 0px' }}>
        <SmallLoader />
      </Box>
    )
  );
};

export default CareerHistoryTable;
