import React from 'react';
import {
  Show, SimpleShowLayout, TextField, NumberField,
  ReferenceField, type ShowProps,
} from 'react-admin';
import CustomShowTitle from '../../components/utils/CustomShowTitle';

const PositionShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<CustomShowTitle resource="position" />} sx={{ marginBottom: '1rem' }}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <TextField source="name" />
        <ReferenceField source="positionGroupId" reference="position_group" link="show">
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default PositionShow;
