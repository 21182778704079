import React from 'react';
import { useMediaQuery, type Theme } from '@mui/material';
import {
  List, Datagrid, SimpleList, TextField, SearchInput, FunctionField,
  ReferenceField, NumberField, ReferenceInput, SelectInput,
  type ListProps,
} from 'react-admin';
import AvatarField from '../../components/fields/AvatarField';
import IsFavouriteInput from '../../components/inputs/IsFavouriteInput';
import CustomListActions from '../../components/utils/CustomListActions';
import type { Player } from '../../interfaces';

const playerListFilters = [
  <SearchInput
    source="lastName"
    variant="filled"
    key="lastName"
    alwaysOn
  />,
  <ReferenceInput
    source="fifaId"
    reference="fifa"
    key="fifaId"
    sort={{
      field: 'name',
      order: 'ASC',
    }}
    alwaysOn
  >
    <SelectInput
      label="FIFA"
      variant="filled"
      optionValue="id"
      optionText="name"
    />
  </ReferenceInput>,
  <ReferenceInput
    source="positionId"
    reference="position"
    key="positionId"
    alwaysOn
  >
    <SelectInput
      source="name"
      variant="filled"
      optionText="name"
      optionValue="id"
    />
  </ReferenceInput>,
  <IsFavouriteInput
    key="is_favourite"
    source="is_favourite"
    alwaysOn
  />,
];

const playerListFiltersSmall = [
  <SearchInput
    source="lastName"
    variant="filled"
    key="lastName"
    alwaysOn={false}
    sx={{
      marginLeft: '5px',
    }}
  />,
  <ReferenceInput
    source="fifaId"
    reference="fifa"
    key="fifaId"
    alwaysOn={false}
    sort={{
      field: 'name',
      order: 'ASC',
    }}
  >
    <SelectInput
      label="FIFA"
      variant="filled"
      optionValue="id"
      optionText="name"
      sx={{
        width: 219,
        marginLeft: '5px',
      }}
    />
  </ReferenceInput>,
  <ReferenceInput
    source="positionId"
    reference="position"
    key="positionId"
    alwaysOn={false}
  >
    <SelectInput
      source="name"
      variant="filled"
      optionText="name"
      optionValue="id"
      sx={{
        width: 219,
        marginLeft: '5px',
      }}
    />
  </ReferenceInput>,
  <IsFavouriteInput
    key="is_favourite"
    source="is_favourite"
    alwaysOn={false}
  />,
];

const PlayerList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomListActions hasFilters resource="player" />}
      filters={!isSmall ? playerListFilters : playerListFiltersSmall}
      filterDefaultValues={{ fifaId: 3 }}
      sort={{
        field: 'rating',
        order: 'DESC',
      }}
      {...props}
    >
      {!isSmall
        ? <Datagrid rowClick="show">
          <FunctionField label="Name" render={(record: Player) => `${record.firstName} ${record.lastName}`} />
          <AvatarField source="fifaIndexId" type="players" size={50} label="" />
          <ReferenceField source="teamId" reference="team" link="show">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="rating" />
          <ReferenceField source="positionId" reference="position" link="show">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
        : <SimpleList
          primaryText={data => `${data.firstName} ${data.lastName}`}
          secondaryText={data => data.rating}
          linkType="show"
        />
      }
    </List>
  );
};

export default PlayerList;
