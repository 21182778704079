import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TrophyCreate from './Create';
import TrophyEdit from './Edit';
import TrophyList from './List';

const Trophy = {
  name: 'trophy',
  icon: EmojiEventsIcon,
  list: TrophyList,
  edit: TrophyEdit,
  create: TrophyCreate,
};

export default Trophy;
