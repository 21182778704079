import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Typography, Avatar, Badge } from '@mui/material';
import { ShowButton, type RaRecord } from 'react-admin';
import type { LatestPlayersListProps } from '../../interfaces';
import { buildFifaIndexUrl } from '../../utils';

const LatestPlayersList: React.FC<LatestPlayersListProps> = ({ players }) => {
  return (
    <List dense>
      {players.map((player: RaRecord, index: number) => {
        const { fifaId, fifaIndexId, firstName, lastName, rating } = player;
        return <ListItem
          secondaryAction={<ShowButton resource="player" record={player} />}
          key={index}
        >
          <ListItemAvatar>
            <Badge
              badgeContent={
                <Typography sx={{ fontSize: '13px' }}>
                  {rating}
                </Typography>
              }
              color="primary"
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Avatar
                alt={`${firstName} ${lastName}`}
                src={buildFifaIndexUrl(fifaId, fifaIndexId, 'players')}
                sx={{
                  width: 60,
                  height: 60,
                }}
              />
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography sx={{ fontSize: '17px' }}>
              {`${firstName} ${lastName}`}
            </Typography>}
          />
        </ListItem>;
      })}
    </List>
  );
};

export default LatestPlayersList;
