import Diversity3Icon from '@mui/icons-material/Diversity3';
import SquadEdit from './Edit';
import SquadList from './List';
import SquadShow from './Show';

const Squad = {
  name: 'squad',
  icon: Diversity3Icon,
  list: SquadList,
  show: SquadShow,
  edit: SquadEdit,
};

export default Squad;
