import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Menu, MenuItem } from '@mui/material';
import { Button, useRedirect } from 'react-admin';
import ImportDialog from './ImportDialog';
import type { CustomCreateButtonProps } from '../../interfaces';

const CustomCreateButton: React.FC<CustomCreateButtonProps> = ({ resource, hasMenu }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const redirect = useRedirect();

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        color="primary"
        size="small"
        onClick={hasMenu === true ? handleOpen : () => redirect('create', resource)}
        label="Create"
      >
        <AddIcon />
      </Button>
      {openMenu ? (
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => redirect('create', resource)}>
            Create
          </MenuItem>
          <MenuItem onClick={() => setOpenDialog(true)}>
            CSV Import
          </MenuItem>
        </Menu>
      ) : null}
      {openDialog ? (
        <ImportDialog
          open={openDialog}
          setOpen={setOpenDialog}
          resource={resource}
        />
      ) : null}
    </>
  );
};

export default CustomCreateButton;
