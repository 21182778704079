import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import {
  Dialog, DialogTitle, DialogContent, Typography,
  Box, IconButton, Button,
} from '@mui/material';
import type { AxiosError } from 'axios';
import Papa from 'papaparse';
import {
  FileInput, FileField, Form, required, useNotify, useLogout,
  useRefresh, type RaRecord,
} from 'react-admin';
import type { ImportDialogProps } from '../../interfaces';
import { ImportData } from '../../services/DataService';

const ImportDialog: React.FC<ImportDialogProps> = ({ open, setOpen, resource }) => {
  const notify = useNotify();
  const logout = useLogout();
  const refresh = useRefresh();

  const onSubmit = (data: Partial<RaRecord>): void => {
    const rawFile = data.file.rawFile;
    Papa.parse(rawFile, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data !== undefined && result.data.length > 0) {
          const dataToCreate = result.data.map((item) => {
            const itemObj = item as Partial<RaRecord>;
            let newObj: Partial<RaRecord> = {};
            if (resource === 'player') {
              newObj = {
                ...itemObj,
                teamId: parseInt(itemObj.teamId),
                age: parseInt(itemObj.age),
                positionId: parseInt(itemObj.positionId),
                fifaIndexId: parseInt(itemObj.fifaIndexId),
                rating: parseInt(itemObj.rating),
                ratingPotential: parseInt(itemObj.rating_potential),
                fifaId: parseInt(itemObj.fifaId),
              };
              delete newObj.rating_potential;
            } else {
              newObj = {
                ...itemObj,
                budget: parseInt(itemObj.budget),
                fifaIndexId: parseInt(itemObj.fifaIndexId),
                rivalTeamId: itemObj.rivalTeamId.length > 0 ? parseInt(itemObj.rivalTeamId) : null,
                squadId: itemObj.squadId.length > 0 ? parseInt(itemObj.squadId) : null,
                fifaId: parseInt(itemObj.fifaId),
              };
              delete newObj.rating_potential;
            }
            return newObj;
          });
          handleImportComplete(dataToCreate);
        }
      },
      error: (error) => {
        notify('Error while loading file', { type: 'error' });
        console.log(error, 'Error File');
      },
    });
  };

  const createData = async (dataToCreate: Array<Partial<RaRecord>>): Promise<string> => {
    const data = await ImportData(dataToCreate, resource);
    return data;
  };

  const handleImportComplete = (dataToCreate: Array<Partial<RaRecord>>): void => {
    createData(dataToCreate)
      .then((resp: string) => {
        notify(`${resource === 'player' ? 'Players' : 'Teams'} were successfully imported`, { type: 'success' });
        refresh();
        setOpen(false);
      })
      .catch(async (err: AxiosError) => {
        if (err.response?.status === 400) notify(`Invalid ${resource} data`, { type: 'error' });
        if (err.response?.status === 401) await logout();
        if (err.response?.status === 409) notify('Duplicate entity', { type: 'error' });
        if (err.response?.status === 500) notify('Server error', { type: 'error' });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            Import data from CSV
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={onSubmit}>
          <FileInput source="file" accept={{ 'application/csv': ['.csv'] }} validate={required()}>
            <FileField source="src" title="title" />
          </FileInput>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '2rem',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              size="small"
              startIcon={<PublishIcon />}
              sx={{
                color: '#fff',
              }}
            >
              Import
            </Button>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ImportDialog;
