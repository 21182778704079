import React from 'react';
import { Switch, FormControlLabel, useMediaQuery, type Theme } from '@mui/material';
import { useListFilterContext } from 'react-admin';
import type { IsFavouriteInputProps } from '../../interfaces';

const IsFavouriteInput: React.FC<IsFavouriteInputProps> = ({ key, source, alwaysOn }) => {
  const [checked, setChecked] = React.useState<boolean>(false);
  const { setFilters, displayedFilters } = useListFilterContext();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
    const newFilterValues = {
      is_favourite: event.target.checked ? 1 : 0,
    };
    setFilters(newFilterValues, displayedFilters);
  };

  return (
    <FormControlLabel
      label="Favourites"
      key={key}
      control={<Switch
        checked={checked}
        onChange={handleChange}
      />}
      sx={{
        marginLeft: isSmall ? '5px' : 0,
      }}
    />
  );
};

export default IsFavouriteInput;
