import type { RaRecord, Identifier } from 'react-admin';

const fifaIndexUrl = process.env.REACT_APP_FIFA_INDEX ?? '';

export const transformResource = (resource: string): string => {
  return resource.includes('_') ? resource.replace(/_/g, '-') : resource;
};

export const transformData = (data: Partial<RaRecord>, resource: string): Partial<RaRecord> => {
  let newData: Partial<RaRecord> = { id: 0 };
  if (resource === 'player') {
    newData = {
      ...data,
      ratingPotential: data.rating_potential,
    };
    delete newData.rating_potential;
  }
  return newData;
};

export const isValidYearFormat = (inputValue: string): boolean => {
  const matches = /^(20[0-9]{2})\/(20[0-9]{2})$/.exec(inputValue);
  if (matches === null) return false;
  const firstYear = parseInt(matches[1], 10);
  const secondYear = parseInt(matches[2], 10);
  return secondYear === firstYear + 1;
};

export const buildFifaIndexUrl = (
  fifaId: number | Identifier,
  fifaIndexId: number,
  type: 'players' | 'teams',
): string => {
  let finalSrc: string = '';
  switch (fifaId) {
  /* eslint-disable indent */
    case 1:
      finalSrc = `${fifaIndexUrl}/FIFA08/images/${type}/10/${fifaIndexId}.png`;
      break;
    case 2:
      finalSrc = `${fifaIndexUrl}/FIFA10/images/${type}/10/${fifaIndexId}.png`;
      break;
    case 3:
      finalSrc = `${fifaIndexUrl}/FIFA11/images/${type}/10/${fifaIndexId}.png`;
      break;
    case 4:
      finalSrc = `${fifaIndexUrl}/FIFA09/images/${type}/10/${fifaIndexId}.png`;
      break;
    default:
      finalSrc = `${fifaIndexUrl}/FIFA10/images/${type}/10/${fifaIndexId}.png`;
      break;
  /* eslint-enable indent */
  }
  return finalSrc;
};
