import React from 'react';
import {
  Grid, Card, CardContent, CardActions, Button, Typography,
  useTheme, useMediaQuery, type Theme,
} from '@mui/material';
import type { AxiosError } from 'axios';
import { useDataProvider, useNotify } from 'react-admin';
import SquadBuilderActions from './SquadBuilderActions';
import SquadGrid from './SquadGrid';
import type { FormationSelectProps, Formation } from '../../interfaces';
import Loader from '../utils/Loader';

const FormationSelect: React.FC<FormationSelectProps> = ({ level, setLevel, fifa, name }) => {
  const [formations, setFormations] = React.useState<Formation[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedFormationId, setSelectedFormationId] = React.useState<number>(0);
  const [selectedFormation, setSelectedFormation] = React.useState<Formation>();

  const theme = useTheme();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const onFormationSelect = (formation: Formation): void => {
    setSelectedFormationId(formation.id);
    setSelectedFormation(formation);
  };

  React.useEffect(() => {
    const getFormations = async (): Promise<Formation[]> => {
      const data = await dataProvider.getList('formation', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      });
      return data.data;
    };

    getFormations().then((resp: Formation[]) => setFormations(resp)).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
    setLoading(false);
  }, []);

  return (
    level === 2 ? !loading
      ? <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        {formations.map((formation: Formation, index: number) => {
          return <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{
              margin: '1rem 0px',
            }}
            key={index}
          >
            <Card
              raised
              onClick={() => onFormationSelect(formation)}
              sx={{
                width: isSmall ? '100%' : '95%',
                cursor: 'pointer',
                border: formation.id === selectedFormationId
                  ? `2px solid ${theme.palette.primary.main}`
                  : `2px solid ${theme.palette.secondary.main}`,
              }}
            >
              <CardContent>
                <Typography variant="h6" component="h2" align="center">
                  {formation.name}
                </Typography>
                <Typography
                  component="span"
                  align="center"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {formation.layout}
                </Typography>
              </CardContent>
              <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={() => setLevel(3)}
                  sx={{
                    color: '#fff',
                    marginBottom: '10px',
                  }}
                  disabled={formation.id !== selectedFormationId}
                >
                  Select
                </Button>
              </CardActions>
            </Card>
          </Grid>;
        })}
        <Grid item xs={12}>
          <SquadBuilderActions
            level={level}
            setLevel={setLevel}
            onSave={() => {}}
          />
        </Grid>
      </Grid> : <Loader size={100} />
      : selectedFormation !== undefined
        ? <SquadGrid
          level={level}
          setLevel={setLevel}
          fifa={fifa}
          formation={selectedFormation}
          name={name}
        />
        : null
  );
};

export default FormationSelect;
