import React from 'react';
import { Grid } from '@mui/material';
import type { AxiosError } from 'axios';
import { Title, useLogout, useNotify, type RaRecord, type Identifier } from 'react-admin';
import FifaCard from '../components/squadBuilder/FifaCard';
import FormationSelect from '../components/squadBuilder/FormationSelect';
import SquadNameDialog from '../components/squadBuilder/SquadNameDialog';
import Loader from '../components/utils/Loader';
import { GetFifaInfo } from '../services/DataService';

const SquadBuilder: React.FC = () => {
  const [selectedFifa, setSelectedFifa] = React.useState<Identifier | null>(null);
  const [fifaData, setFifaData] = React.useState<RaRecord[]>([]);
  const [fifa08, setFifa08] = React.useState<RaRecord>({ id: 0 });
  const [fifa09, setFifa09] = React.useState<RaRecord>({ id: 0 });
  const [fifa10, setFifa10] = React.useState<RaRecord>({ id: 0 });
  const [fifa11, setFifa11] = React.useState<RaRecord>({ id: 0 });
  const [fetched, setFetched] = React.useState<boolean>(false);
  const [level, setLevel] = React.useState<number>(1);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [squadName, setSquadName] = React.useState<string>('');

  const logout = useLogout();
  const notify = useNotify();

  const handleStart = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    if (selectedFifa === fifa08.id) {
      if (fifa08.player_count < 18) notify('Squad builder is not available for selected FIFA', { type: 'error' });
      else setOpenDialog(true);
    }
    if (selectedFifa === fifa09.id) {
      if (fifa09.player_count < 18) notify('Squad builder is not available for selected FIFA', { type: 'error' });
      else setOpenDialog(true);
    }
    if (selectedFifa === fifa10.id) {
      if (fifa10.player_count < 18) notify('Squad builder is not available for selected FIFA', { type: 'error' });
      else setOpenDialog(true);
    }
    if (selectedFifa === fifa11.id) {
      if (fifa11.player_count < 18) notify('Squad builder is not available for selected FIFA', { type: 'error' });
      else setOpenDialog(true);
    }
  };

  const getInfo = async (): Promise<RaRecord[]> => {
    const data = await GetFifaInfo();
    return data;
  };

  React.useEffect(() => {
    if (!fetched) {
      getInfo()
        .then((resp: RaRecord[]) => {
          setFifaData(resp);
          const fifa08Data = resp.filter((item: RaRecord) => item.name === 'FIFA 08');
          const fifa09Data = resp.filter((item: RaRecord) => item.name === 'FIFA 09');
          const fifa10Data = resp.filter((item: RaRecord) => item.name === 'FIFA 10');
          const fifa11Data = resp.filter((item: RaRecord) => item.name === 'FIFA 11');
          if (fifa08Data.length > 0) setFifa08(fifa08Data[0]);
          if (fifa09Data.length > 0) setFifa09(fifa09Data[0]);
          if (fifa10Data.length > 0) setFifa10(fifa10Data[0]);
          if (fifa11Data.length > 0) setFifa11(fifa11Data[0]);
        })
        .catch(async (err: AxiosError) => {
          if (err.response?.status === 401) await logout();
          if (err.response?.status === 500) notify('Server Error', { type: 'error' });
        });
      setFetched(true);
    }
  }, [selectedFifa]);

  return (
    <>
      <Title title="Squad Builder" />
      {fetched ? (
        <>
          {level === 1
            ? <>
              <Grid container>
                {fifaData.map((item: RaRecord, index: number) => {
                  return (
                    <FifaCard
                      data={item}
                      selectedFifa={selectedFifa}
                      setSelectedFifa={setSelectedFifa}
                      handleStart={handleStart}
                      key={index}
                    />
                  );
                })}
              </Grid>
              <SquadNameDialog
                open={openDialog}
                setOpen={setOpenDialog}
                setLevel={setLevel}
                setName={setSquadName}
              />
            </>
            : <FormationSelect
              level={level}
              setLevel={setLevel}
              fifa={selectedFifa}
              name={squadName}
            />
          }
        </>
      ) : (
        <Loader size={70} />
      )}
    </>
  );
};

export default SquadBuilder;
