import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Stack, Button } from '@mui/material';
import type { AxiosError } from 'axios';
import {
  Form, ReferenceInput, AutocompleteInput, NumberInput, useDataProvider,
  useNotify, required, type RaRecord,
} from 'react-admin';
import type { CreateNotablePlayerFormProps, NotablePlayerToCreate } from '../../interfaces';

const CreateNotablePlayerForm: React.FC<CreateNotablePlayerFormProps> = ({ managedSeasonId, fifaId, setRefresh }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const createPlayer = async (entityData: NotablePlayerToCreate): Promise<RaRecord> => {
    const data = await dataProvider.create('notable_player', { data: entityData });
    return data.data;
  };

  const onSubmit = (data: Partial<RaRecord>): void => {
    const playerData: NotablePlayerToCreate = {
      playerId: data.playerId,
      managedSeasonId,
      appearances: data.appearances,
      goals: data.goals,
      assists: data.assists,
      yellowCards: data.yellowCards,
      redCards: data.redCards,
      cleanSheets: data.cleanSheets,
      fifaId,
    };

    createPlayer(playerData)
      .then((resp: RaRecord) => {
        notify('Player was successfully created', { type: 'success' });
        setRefresh(true);
      })
      .catch((err: AxiosError) => {
        if (err?.message === 'Duplicate entity') {
          notify('Duplicate entity', { type: 'error' });
          return;
        }
        notify('Server error', { type: 'error' });
      });
  };

  return (
    <Box
      sx={{
        marginTop: '2rem',
      }}
    >
      <Form onSubmit={onSubmit}>
        <Box
          sx={{
            marginTop: '2rem',
          }}
        >
          <Stack
            direction="column"
            spacing={1}
          >
            <ReferenceInput
              source="playerId"
              reference="player"
              filter={{
                fifaId,
              }}
            >
              <AutocompleteInput
                source="lastName"
                variant="filled"
                validate={required()}
                optionText={(record) => `${record.firstName} ${record.lastName}`}
                optionValue="id"
                filterToQuery={(searchText: string) => ({ lastName: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 2;
                }}
                noOptionsText="Type 3 characters"
                sx={{
                  width: 219,
                }}
              />
            </ReferenceInput>
            <NumberInput
              source="appearances"
              label="Appearances"
              variant="filled"
              validate={required()}
              sx={{
                width: 219,
              }}
            />
            <NumberInput
              source="goals"
              label="Goals"
              variant="filled"
              validate={required()}
              sx={{
                width: 219,
              }}
            />
            <NumberInput
              source="assists"
              label="Assists"
              variant="filled"
              validate={required()}
              sx={{
                width: 219,
              }}
            />
            <NumberInput
              source="yellowCards"
              label="Yellow cards"
              variant="filled"
              validate={required()}
              sx={{
                width: 219,
              }}
            />
            <NumberInput
              source="redCards"
              label="Red cards"
              variant="filled"
              validate={required()}
              sx={{
                width: 219,
              }}
            />
            <NumberInput
              source="cleanSheets"
              label="Clean sheets"
              variant="filled"
              validate={required()}
              sx={{
                width: 219,
              }}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            margin: '2rem 0px',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            sx={{
              color: '#fff',
            }}
          >
            Save
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default CreateNotablePlayerForm;
