import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import { Button, EditButton, TopToolbar, useRecordContext, useNotify, useLogout } from 'react-admin';
import CopyPlayerDialog from './CopyPlayerDialog';
import type { CustomShowActionsProps } from '../../interfaces';
import * as API from '../../services/DataService';
import AddSeasonDialog from '../career/AddSeasonDialog';

const CustomShowActions: React.FC<CustomShowActionsProps> = ({
  resource,
  hasCareerButton,
  setRefreshSeasons,
  hasFavouriteButton,
  hasCopyButton,
}) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [isFavourite, setIsFavourite] = React.useState<boolean>(false);
  const [openCopyDialog, setOpenCopyDialog] = React.useState<boolean>(false);

  const notify = useNotify();
  const logout = useLogout();
  const record = useRecordContext();

  const handleFavourite = async (isFavourite: number): Promise<string> => {
    const playerId = get(record, ['id'], 0);
    const data = await API.HandleFavourite(playerId, isFavourite);
    return data;
  };

  const handleAdd = (): void => {
    handleFavourite(1).then((resp: string) => {
      notify('Player added to favourites', { type: 'success' });
      setIsFavourite(true);
    }).catch(async (err: AxiosError) => {
      if (err.response?.status === 401) await logout();
      if (err.response?.status === 500) notify('Server error', { type: 'error' });
    });
  };

  const handleRemove = (): void => {
    handleFavourite(0).then((resp: string) => {
      notify('Player removed from favourites', { type: 'success' });
      setIsFavourite(false);
    }).catch(async (err: AxiosError) => {
      if (err.response?.status === 401) await logout();
      if (err.response?.status === 500) notify('Server error', { type: 'error' });
    });
  };

  React.useEffect(() => {
    if (resource === 'player' && record !== undefined) {
      const favourite = get(record, ['is_favourite'], 0);
      setIsFavourite(favourite > 0);
    }
  }, [resource]);

  return (
    <>
      <TopToolbar>
        <EditButton resource={resource} />
        {hasCareerButton !== undefined
          ? <Button
            color="primary"
            size="small"
            label="Add season"
            onClick={() => setOpenDialog(true)}
          >
            <AddIcon />
          </Button>
          : null
        }
        {hasFavouriteButton !== undefined
          ? <Button
            color="primary"
            size="small"
            label={isFavourite ? 'Remove from favourites' : 'Add to favourites'}
            onClick={isFavourite ? handleRemove : handleAdd}
          >
            {isFavourite ? <StarIcon /> : <StarBorderIcon />}
          </Button>
          : null
        }
        {hasCopyButton !== undefined
          ? <Button
            color="primary"
            size="small"
            label="Copy player"
            onClick={() => setOpenCopyDialog(true)}
          >
            <ContentCopyIcon />
          </Button>
          : null
        }
      </TopToolbar>
      {openDialog
        ? <AddSeasonDialog
          open={openDialog}
          setOpen={setOpenDialog}
          setRefreshSeasons={setRefreshSeasons}
        />
        : null
      }
      {openCopyDialog
        ? <CopyPlayerDialog
          open={openCopyDialog}
          setOpen={setOpenCopyDialog}
          record={record ?? { id: 0 }}
        />
        : null
      }
    </>
  );
};

export default CustomShowActions;
