import React from 'react';
import type { AxiosError } from 'axios';
import {
  Edit, SimpleForm, TextInput, NumberInput, ReferenceInput, AutocompleteInput,
  SelectInput, FormDataConsumer, required, useDataProvider, useNotify,
  type EditProps,
} from 'react-admin';
import FifaInput from '../../components/inputs/FifaInput';
import CustomEditTitle from '../../components/utils/CustomEditTitle';
import type { Team } from '../../interfaces';

const PlayerEdit: React.FC<EditProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [teamsCopy, setTeamsCopy] = React.useState<Team[]>([]);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const getTeams = async (): Promise<Team[]> => {
    const data = await dataProvider.getList('team', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });
    return data.data;
  };

  React.useEffect(() => {
    getTeams().then((resp: Team[]) => {
      setTeams(resp);
      setTeamsCopy(resp);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  }, []);

  React.useEffect(() => {
    if (parseInt(selectedVal) > 0) {
      const filtered = teamsCopy.filter((team: Team) => team.fifaId === parseInt(selectedVal));
      setTeams(filtered);
    }
  }, [selectedVal]);

  return (
    <Edit
      redirect="show"
      title={<CustomEditTitle resource="player" />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="firstName" variant="filled" validate={required()} />
        <TextInput source="lastName" variant="filled" validate={required()} />
        <FifaInput
          source="fifaId"
          label="FIFA"
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) => formData.fifaId !== undefined &&
            <AutocompleteInput
              source="teamId"
              choices={teams}
              variant="filled"
              validate={required()}
              optionText="name"
              optionValue="id"
              filterToQuery={(searchText: string) => ({ name: searchText })}
              shouldRenderSuggestions={(val: string) => {
                return val.trim().length > 2;
              }}
              noOptionsText="Type 3 characters"
              sx={{
                width: 219,
              }}
            />
          }
        </FormDataConsumer>
        <NumberInput
          source="age"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 15, max: 45 } }}
          sx={{
            width: 219,
          }}
        />
        <NumberInput
          source="fifaIndexId"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0 } }}
          sx={{
            width: 219,
          }}
        />
        <ReferenceInput
          source="positionId"
          reference="position"
        >
          <SelectInput
            source="name"
            variant="filled"
            optionText="name"
            optionValue="id"
            validate={required()}
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
        <NumberInput
          source="rating"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0, max: 99 } }}
          sx={{
            width: 219,
          }}
        />
        <NumberInput
          source="rating_potential"
          label="Potentional rating"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0, max: 99 } }}
          sx={{
            width: 219,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default PlayerEdit;
