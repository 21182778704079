import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, IconButton } from '@mui/material';
import type { AxiosError } from 'axios';
import { useDataProvider, useNotify, useRedirect, type RaRecord } from 'react-admin';
import SquadBuilderActions from './SquadBuilderActions';
import SquadPlayerCard from './SquadPlayerCard';
import SubstitutesDialog from './SubstitutesDialog';
import type { SquadGridProps, Squad, SquadToSave, Player } from '../../interfaces';
import * as API from '../../services/DataService';

const SquadGrid: React.FC<SquadGridProps> = ({ level, setLevel, fifa, formation, name }) => {
  const [playersInSquad, setPlayersInSquad] = React.useState<RaRecord[]>([]);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [playerList, setPlayerList] = React.useState<Player[]>([]);

  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { defenders, midfielders, attackers, layout } = formation;

  const returnStandardGrid = (amount: number, type: string): JSX.Element[] => {
    const components = [];
    let order: number = 0;
    for (let i = 0; i < amount; i++) {
      if (type === 'def') order = i + 1;
      if (type === 'mid') order = i + defenders + 1;
      if (type === 'att') order = i + defenders + midfielders + 1;
      components.push(<Grid
        item
        xs={12}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        key={i}
        sx={{
          margin: '1rem auto 0px',
        }}
      >
        <SquadPlayerCard
          type={type}
          fifa={fifa}
          playersInSquad={playersInSquad}
          setPlayersInSquad={setPlayersInSquad}
          order={order}
          playerList={playerList}
        />
      </Grid>);
    }
    return components;
  };

  const returnCustomGrid = (amount: number, type: string): JSX.Element[] => {
    const components = [];
    let order: number = 0;
    for (let i = 0; i < amount; i++) {
      if (type === 'cdm') order = defenders + amount;
      if (type === 'cm') order = defenders + parseInt(layout.split('-')[1]) + amount;
      if (type === 'cam') order = defenders + parseInt(layout.split('-')[2]) + amount + 1;
      components.push(<Grid
        item
        xs={12}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        key={i}
        sx={{
          margin: '1rem auto 0px',
        }}
      >
        <SquadPlayerCard
          type="mid"
          fifa={fifa}
          playersInSquad={playersInSquad}
          setPlayersInSquad={setPlayersInSquad}
          order={order}
          playerList={playerList}
        />
      </Grid>);
    }
    return components;
  };

  const createSquad = async (squadToSave: SquadToSave): Promise<Squad> => {
    const squadData = await dataProvider.create('squad', {
      data: squadToSave,
    });
    return squadData.data;
  };

  const handleSave = (): void => {
    const squadToSave = {
      name,
      formationId: formation.id,
      fifa,
    };
    createSquad(squadToSave).then(async (resp: Squad) => {
      const playersWithSquad: RaRecord[] = playersInSquad.map((player: RaRecord) => {
        return {
          ...player,
          squadId: resp.id,
        };
      });
      const playerData = await API.AddPlayersToSquad(playersWithSquad);
      if (playerData !== undefined) {
        notify('Squad was saved', { type: 'success' });
        redirect('list', 'squad');
      }
    }).catch((err: AxiosError) => {
      if (err?.message === 'Duplicate entity') notify('Duplicate entity', { type: 'error' });
    });
  };

  React.useEffect(() => {
    const getPlayers = async (): Promise<Player[]> => {
      /* get players by fifa */
      const data = await dataProvider.getList('player', {
        pagination: { page: 1, perPage: 100000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { fifaId: fifa ?? 0 },
      });
      return data.data;
    };

    getPlayers().then((resp: Player[]) => {
      setPlayerList(resp);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('Server Error', { type: 'error' });
    });
  }, []);

  return level === 3
    ? <>
      <Grid container>
        {returnStandardGrid(attackers, 'att')}
      </Grid>
      {layout.split('-').length === 5
        ? <>
          <Grid container>
            {returnCustomGrid(parseInt(layout.split('-')[3]), 'cam')}
          </Grid>
          <Grid container>
            {returnCustomGrid(parseInt(layout.split('-')[2]), 'cm')}
          </Grid>
          <Grid container>
            {returnCustomGrid(parseInt(layout.split('-')[1]), 'cdm')}
          </Grid>
        </>
        : <Grid container>
          {returnStandardGrid(midfielders, 'mid')}
        </Grid>
      }
      <Grid container>
        {returnStandardGrid(defenders, 'def')}
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{ margin: '1rem auto 0px' }}>
          <SquadPlayerCard
            type="gk"
            fifa={fifa}
            playersInSquad={playersInSquad}
            setPlayersInSquad={setPlayersInSquad}
            order={0}
            playerList={playerList}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: '1rem auto' }}>
          <IconButton size="medium" onClick={() => setOpenDialog(true)}>
            <AddCircleIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: '1rem auto' }}>
          <SquadBuilderActions
            level={level}
            setLevel={setLevel}
            onSave={handleSave}
            disabled={playersInSquad.length !== 18}
          />
        </Grid>
      </Grid>
      {openDialog ? <SubstitutesDialog
        open={openDialog}
        setOpen={setOpenDialog}
        playersInSquad={playersInSquad}
        setPlayersInSquad={setPlayersInSquad}
        playerList={playerList}
        fifa={fifa}
      /> : null}
    </>
    : null;
};

export default SquadGrid;
