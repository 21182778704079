import React from 'react';
import { useMediaQuery, type Theme } from '@mui/material';
import { List, Datagrid, SimpleList, TextField, NumberField, SearchInput, type ListProps } from 'react-admin';
import CustomListActions from '../../components/utils/CustomListActions';

const squadListFilters = [
  <SearchInput
    source="name"
    variant="filled"
    key="name"
    alwaysOn
  />,
];

const squadListFiltersSmall = [
  <SearchInput
    source="name"
    variant="filled"
    key="name"
    alwaysOn={false}
    sx={{
      marginLeft: '5px',
    }}
  />,
];

const FormationList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomListActions hasFilters resource="formation" />}
      filters={!isSmall ? squadListFilters : squadListFiltersSmall}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall
        ? <Datagrid rowClick="show">
          <NumberField source="id" />
          <TextField source="name" />
          <TextField source="layout" />
        </Datagrid>
        : <SimpleList
          primaryText={data => data.name}
          secondaryText={data => data.layout}
          linkType="show"
        />
      }
    </List>
  );
};

export default FormationList;
