import DialpadIcon from '@mui/icons-material/Dialpad';
import FormationCreate from './Create';
import FormationEdit from './Edit';
import FormationList from './List';
import FormationShow from './Show';

const Formation = {
  name: 'formation',
  icon: DialpadIcon,
  list: FormationList,
  show: FormationShow,
  edit: FormationEdit,
  create: FormationCreate,
};

export default Formation;
